import React, { useContext, useState, useEffect, useRef } from "react";
import ApplicationContext from "../../ApplicationContext";
import StoreContext from "../../StoreContext";
import { AiFillPlusCircle } from "react-icons/ai";
import AddPaymentMethod from "./AddPaymentMethod";
import { toast } from "react-toastify";
import axios from "axios";
import {
  APPLICATION,
  BASE_API,
  ORDER_FEE,
  STORE_NAME_CODE,
} from "../../constants";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import { useNavigate } from "react-router-dom";
import { getNowTime } from "../../utils/time/GetNowTime";
import ReCAPTCHA from "react-google-recaptcha";
import { checkIfStoreIsOpen } from "../../utils/store/CheckIfStoreIsOpen";
import { checkIfStoreDeliveryIsOpen } from "../../utils/store/CheckIfStoreDeliveryIsOpen";

function CheckoutForm(props) {
  const navigate = useNavigate();
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState("");
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Save token to send to the backend
  };
  const resetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset(); // Reset the CAPTCHA
      setCaptchaToken(""); // Clear the token
    }
  };

  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState();
  const [previouslyActivePayment, setPreviouslyActivePayment] = useState();

  const [addGiftCardCode, setAddGiftCardCode] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [giftCodeSuccess, setGiftCodeSuccess] = useState(false);

  const [isPromoCodeExclusiveItems, setIsPromoCodeExclusiveItems] = useState(false);
  const [isActiveGiftCardAfterOrder, setIsActiveGiftCardAfterOrder] = useState(true);

  const [cardNumber, setCardNumber] = useState();
  const [expDate, setExpDate] = useState();
  const [cvcCode, setCvcCode] = useState();
  const [address, setAddress] = useState();
  const [apartmentNumber, setApartmentNumber] = useState();
  const [zipCode, setZipCode] = useState();
  const newOrderId = CreateGuid();
  let orderData = {
    Items: [],
  };

  const [isDemo, setIsDemo] = useState("AuthorizeNet");

  useEffect(() => {
    const pathname = window.location.pathname;
    const store = pathname.split("/")[1];
    if (store === "demostore" || store === "demostore2") {
      setIsDemo("AuthorizeNetDemo");
    }
  }, []);

  useEffect(() => {
    getCustomerRewardsPoints();
  }, []);

  useEffect(() => {
    for (var i = 0; i < props.allPromoCodes.length; i++) {
      if (props.allPromoCodes[i].displayMessage === 1) {
        if (props.allPromoCodes[i].promoCodePercentDiscount || props.allPromoCodes[i].promoCodePercentDiscount > 0) {
          toast.success(`Use Promo Code: ${props.allPromoCodes[i].promoCode} for ${props.allPromoCodes[i].promoCodePercentDiscount * 100}% off your order!`);
        } else {
          toast.success(`Use Promo Code: ${props.allPromoCodes[i].promoCode} for $${props.allPromoCodes[i].promoCodeValue} off your order!`);
        }
      }
    }
  }, [props.allPromoCodes]);

  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   const store = pathname.split("/")[1];
  //   if (store === "demostore" || store === "demostore2") {
  //     setIsDemo("AuthorizeNetDemo");
  //   }
  //   getCustomerRewardsPoints();
  // }, []);

  useEffect(() => {
    var discountedTax = null;
    var discountedSubtotal = null;
    var discountedTotal = null;
    var tax = parseFloat(props.taxAmount);

    if (
      props.discountedSubtotal &&
      props.discountedTax &&
      props.discountedTotal
    ) {
      discountedSubtotal = props.discountedSubtotal;
      discountedTax = props.discountedTax;
      discountedTotal = parseFloat(props.discountedTotal);
    }
    orderData.OrderID = newOrderId;
    orderData.OrderStatusID = 1;
    orderData.DiscountedSubtotal = discountedSubtotal;
    orderData.DiscountedTax = discountedTax;
    orderData.DiscountedTotal = discountedTotal;
    orderData.Fee = ORDER_FEE;
    orderData.PhoneNumber = appContext.userInfo.phoneNumber;
    orderData.Email = appContext.userInfo.email;
    orderData.DeliveryFee = props.deliveryFee;
    orderData.OrderSubtotal = appContext.cart.CartPrice;
    orderData.OrderTax = tax;

    for (var a = 0; a < appContext.cartItems.length; a++) {
      var newCartItemID = CreateGuid();
      orderData.Items[a] = {
        OrderItemID: newCartItemID,
        OrderItem_MenuItemID: appContext.cartItems[a].CartItem_MenuItemID,
        ItemQuantity: appContext.cartItems[a].ItemQuantity,
        ItemTotalPrice: appContext.cartItems[a].ItemTotalPrice,
        ItemSpecialRequests: appContext.cartItems[a].ItemSpecialRequests,
        ItemName: appContext.cartItems[a].ItemName,
        ItemPrice: appContext.cartItems[a].ItemPrice,
        SizeCode: appContext.cartItems[a].SizeCode,
        OtherPrice: appContext.cartItems[a].ItemPrice,
        Extras: [],
      };
      if (appContext.cartItems[a].RewardCost) {
        orderData.Items[a].RewardPoints = appContext.cartItems[a].RewardCost;
      } else {
        orderData.Items[a].RewardPoints = 0;
      }
      if (!orderData.Items[a].SizeCode) {
        orderData.Items[a].SizeCode = null;
      }

      for (var b = 0; b < appContext.cartItems[a].Extras.length; b++) {
        if (appContext.cartItems[a].Extras[b].PizzaFirstHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaFirstHalf = 0;
        }
        if (appContext.cartItems[a].Extras[b].PizzaSecondHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaSecondHalf = 0;
        }
        orderData.Items[a].Extras[b] = {
          OrderExtra_OrderItemID: newCartItemID,
          OrderExtra_MenuItemExtraID:
            appContext.cartItems[a].Extras[b].CartExtra_MenuItemExtraID,
          PizzaFirstHalf: appContext.cartItems[a].Extras[b].PizzaFirstHalf,
          PizzaSecondHalf: appContext.cartItems[a].Extras[b].PizzaSecondHalf,
          PizzaHalfCode: appContext.cartItems[a].Extras[b].PizzaHalfCode,
          OrderExtraName: appContext.cartItems[a].Extras[b].ExtraName,
          ExtraPrice: appContext.cartItems[a].Extras[b].ExtraPrice,
        };
      }
    }
  }, [newOrderId]);

  useEffect(() => {
    if (props.customerPaymentProfiles.length === 1 && isAddingPaymentMethod) {
      setSelectedPayment(props.customerPaymentProfiles[0]);
      setIsAddingPaymentMethod(false);
    } else if (
      props.customerPaymentProfiles.length === 1 &&
      !isAddingPaymentMethod
    ) {
      setSelectedPayment(props.customerPaymentProfiles[0]);
    } else if (props.customerPaymentProfiles.length > 1) {
      setIsAddingPaymentMethod(false);
    } else {
      setSelectedPayment();
    }
  }, [props.customerPaymentProfiles]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert("Please complete the CAPTCHA to prove you are not a robot!");
      return;
    }

    var isOpen = checkIfStoreIsOpen(storeContext.hours, storeContext.storeClosures);
    var isDeliveryOpen = checkIfStoreDeliveryIsOpen(storeContext.deliveryHours, storeContext.storeClosures);

    if (isPromoCodeExclusiveItems) {
      for (var a = 0; a < appContext.cartItems.length; a++) {
        if (appContext.cartItems[a].ExclusivePromoCode !== props.promoCode) {
          toast.error(`You have items in your cart that are not valid for this Promo Code: ${props.promoCode}`);
          return;
        }
      }
    }

    if (document.getElementById("submit")) {
      //PREVENT Double click on submit code
      document.getElementById("submit").disabled = true;
      document.getElementById("payment_method").display = false;
      document.getElementById("button-text").innerText = "PROCESSING...";

      // Use setTimeout to change innerText back to "CHECKOUT" after 6 seconds
      setTimeout(function () {
        document.getElementById("submit").disabled = false;
        document.getElementById("button-text").innerText = "COMPLETE CHECKOUT";
      }, 6000); // 6000 milliseconds = 6 seconds
    }

    if (storeContext.isActive !== "true") {
      toast.error(
        "Sorry we are closed right now. Please come back soon and try again."
      );
      return;
    }
    if (!orderData.Items) {
      return;
    }
    if (props.orderType === "Delivery") {
      if (orderData.OrderTotal < storeContext.deliveryMin) {
        toast.error(
          `Minimum Order amount for Delivery is $${storeContext.deliveryMin}. Please adjust your order and try again.`
        );
        return;
      }
      if (!props.selectedAddress) {
        toast.error("Please select an address for Delivery orders!");
        return;
      }
    }
    if (props.orderType === "Pickup") {
      if (isOpen !== "Open" && props.nowOrLater !== "Later") {
        toast.error(
          "We are not open for Pickup Orders right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (props.orderType === "Delivery") {
      if (isDeliveryOpen !== "Open" && props.nowOrLater !== "Later") {
        toast.error(
          "We are not open for Delivery right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (props.nowOrLater === "Later" && (!props.selectedDay || !props.selectedTime)) {
      toast.error(
        "Please make sure you have a selected day and time for future orders!"
      );
      return;
    }
    if (!props.orderType) {
      toast.error("Please select either Pickup or Delivery");
      return;
    }

    var orderETA;
    //if payment is successful
    if (props.orderType === "Pickup") {
      orderETA = storeContext.pickupETA;
    } else if (props.orderType === "Delivery") {
      orderETA = storeContext.deliveryETA;
    } else {
      toast.error("Order Type error: Please reload the page and try again.");
      return;
    }

    if (
      props.orderType === "Delivery" &&
      (!props.selectedAddress.streetAddress ||
        !props.selectedAddress.residenceID ||
        !props.selectedAddress.city ||
        !props.selectedAddress.stateName ||
        !props.selectedAddress.zipCode)
    ) {
      toast.error("Order Address error: Please reload the page and try again.");
      return;
    }

    if (props.customerOptsPayLater || parseFloat(props.finalTotal) === 0 || parseFloat(props.finalTotal) < 0) {
      handleCheckout(orderETA);
    } else {
      if (cardNumber && expDate && cvcCode && zipCode) {
        //if card details are entered charge the card
        var authNetPaymentResult;
        var profileId;
        var paymentProfileId;
        var transactionId;

        try {
          authNetPaymentResult = await authorizeAuthorizeNetPayment();
          var errorData = {
            OrderID: newOrderId,
            Message:
              appContext.userInfo.phoneNumber +
              " - new card - " +
              appContext.userInfo.role +
              " " +
              authNetPaymentResult.data.data.transactionResponse.responseCode,
          };
          await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, errorData, {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          });
        } catch (error) {
          console.error("Error in payment authorization:", error);
        } //IF transaction is successful send order details to database (this is handled by handleCheckout)

        if (
          authNetPaymentResult.data.data.transactionResponse.responseCode ===
          "1"
        ) {
          await handleCheckout(orderETA);
        } else {
          //else payment is NOT successful
          if (
            authNetPaymentResult.data.data.transactionResponse.errors[0]
              .errorCode === "27"
          ) {
            toast.error(
              "❗Uh oh! It seems there's an issue with your billing address. Your bank may have temporarily authorized the transaction, but don't worry, we won't actually charge you. Please double-check and re-enter your billing information to complete your order",
              {
                autoClose: false,
                closeOnClick: true,
                closeButton: true,
              }
            );
          } else {
            toast.error(
              authNetPaymentResult.data.data.transactionResponse.errors[0]
                .errorText
            );
          }
          return;
        }

        if (appContext.userInfo.customerID) {
          //if they ARE a CUSTOMER already in auth net (so we do NOT createNewAuthNetCustomer)
          var createPaymentProfileResult =
            await createNewAuthNetCustomerPaymentProfile();
          profileId = appContext.userInfo.customerID;
          paymentProfileId = createPaymentProfileResult.PaymentProfileID;
          transactionId = authNetPaymentResult.TransactionID;

          var newAppContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          //else they are NOT a CUSTOMER in auth net yet (so we do createNewAuthNetCustomer)
          var newAuthNetCustomerResult = await createNewAuthNetCustomer();
          profileId = newAuthNetCustomerResult.ProfileID;
          paymentProfileId = newAuthNetCustomerResult.PaymentProfileID;
          transactionId = authNetPaymentResult.TransactionID;
        }
        await updateOrderWithAuthNetInfo(
          newOrderId,
          transactionId,
          profileId,
          paymentProfileId
        );
      } else if (selectedPayment) {
        //else the card details are NOT entered charge the selected payment method
        try {
          var chargePaymentProfileResult = await chargeCustomerByPaymentProfile(
            appContext.userInfo.customerID,
            selectedPayment.customerPaymentProfileId
          );
          var data = {
            OrderID: newOrderId,
            Message:
              appContext.userInfo.phoneNumber +
              " - payment profile - " +
              appContext.userInfo.role +
              " " +
              chargePaymentProfileResult.Res.responseCode,
          };
          await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          });
        } catch (error) {
          console.error("Error in vaulted payment authorization:", error);
        } //IF transaction is successful send order details to database (this is handled by handleCheckout)

        transactionId = chargePaymentProfileResult.TransactionID;
        profileId = chargePaymentProfileResult.ProfileID;
        paymentProfileId = chargePaymentProfileResult.PaymentProfileID;

        if (chargePaymentProfileResult.Res.responseCode === "1") {
          await handleCheckout(orderETA);
        } else {
          toast.error(chargePaymentProfileResult.Res.errors[0].errorText);
          return;
        }

        await updateOrderWithAuthNetInfo(
          newOrderId,
          transactionId,
          profileId,
          paymentProfileId
        );
        var newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      } else {
        toast.error(
          "Please fill out all payment details proceeding to checkout!"
        );
      }
    }
  };

  async function chargeCustomerByPaymentProfile(profileId, paymentProfileId) {
    try {
      var amount;
      if (storeContext.chargePercent !== null) {
        amount = props.discountedTotal;
      } else {
        amount = props.finalTotal;
      }
      var chargeProfile = {
        Amount: amount,
        ProfileID: profileId,
        PaymentProfileID: paymentProfileId,
      };

      chargeProfile.mike = captchaToken;
      console.log(chargeProfile);

      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/ChargeCustomerPaymentProfile`,
        chargeProfile,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      console.log(dataresp);

      resetCaptcha();
      return {
        TransactionID: dataresp.data.transactionResponse.transId,
        ProfileID: dataresp.data.transactionResponse.profile.customerProfileId,
        PaymentProfileID:
          dataresp.data.transactionResponse.profile.customerPaymentProfileId,
        Res: dataresp.data.transactionResponse,
      };
    } catch (err) {
      toast.error("Failed to charge customer payment profile.");
    }
  }

  async function updateOrderWithAuthNetInfo(
    newOrderId,
    transactionId,
    profileId,
    paymentProfileId
  ) {
    var authNetData = {
      OrderID: newOrderId,
      CustomerID: profileId,
      TransactionID: transactionId,
      PaymentProfileID: paymentProfileId,
    };
    authNetData = JSON.stringify(authNetData);
    await axios.put(
      `${BASE_API}api/Order/UpdateOrdersAuthNetDetails`,
      authNetData,
      {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
    );
  }

  async function handleCheckout(orderETA) {
    var pointsEarnedThisOrder = 0;
    var newPointsBalance = 0;
    var userPointsData = {};
    var newContext = {};
    var newUserInfo = {};
    var newAppContext = {};
    var total;
    try {
      var utcISOString = getNowTime(storeContext.tzOffset);

      orderData.OrderType = props.orderType;
      orderData.OrderTip = props.tipAmount.amount;
      orderData.OrderedDate = utcISOString;
      orderData.OrderETA = orderETA;
      orderData.DeliveryFee = props.deliveryFee;
      if (props.customerOptsPayLater) {
        orderData.PayLater = "true";
        orderData.PercentageFee = 0;
      } else {
        orderData.PayLater = "";
        orderData.PercentageFee = props.isCashAdjustment;
      }
      orderData.GiftCardDiscount = props.giftCardTotalForOrder;
      if (props.giftCardTotalForOrder > 0 && storeContext.chargePercent) {
        orderData.GiftCardDiscountDiscounted = parseFloat(props.giftCardDiscountedTotalForOrder);
      } else {
        orderData.GiftCardDiscountDiscounted = 0;
      }
      orderData.PromoDiscount = props.promoValue;
      if (props.nowOrLater === "Later") {
        const utcDate = props.selectedDay.mysqlDate.split("T")[0];
        const utcTime = props.selectedTime.est;
        orderData.RequestedDate = utcDate + "T" + utcTime;
      } else {
        orderData.RequestedDate = utcISOString;
      }
      if (storeContext.chargePercent) {
        orderData.DiscountedChargePercent = storeContext.chargePercent;
      }
      if (props.orderType === "Delivery") {
        orderData.OrderAddress = props.selectedAddress.residenceID;
        orderData.StreetAddress =
          props.selectedAddress.streetAddress +
          ", " +
          props.selectedAddress.city +
          " " +
          props.selectedAddress.stateName +
          " " +
          props.selectedAddress.zipCode;
      }
      if (
        appContext.userInfo.role === "GuestUser" ||
        appContext.userInfo.role === "Guest"
      ) {
        orderData.Name = appContext.userInfo.firstName;
      } else if (
        appContext.userInfo.role === "Admin" ||
        appContext.userInfo.role === "User"
      ) {
        orderData.Name =
          appContext.userInfo.firstName + " " + appContext.userInfo.lastName;
      } else {
        orderData.Name = "-";
      }

      var orderDataJson = JSON.stringify(orderData);
      await axios.post(`${BASE_API}api/Order/NewOrder`, orderDataJson, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
      toast.success("Order Success");

      if (storeContext.nameCode === "unclesalsbuddlake" || storeContext.nameCode === "demostore" || storeContext.nameCode === "demostore2") {
        await axios.post(`${BASE_API}api/Order/SecondPrinter`, orderDataJson, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      }

      if (props.promoCodeSuccess) {
        await redeemPromoCode();
      }
      if (giftCodeSuccess) {
        await updateGiftCard(isActiveGiftCardAfterOrder, giftCode, props.giftCardBalanceAfterOrder);
      }

      //if the store has a rewards program
      if (storeContext.rewardsProgram === "true") {
        //if it is a user update their reward points
        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          if (orderData.DiscountedTotal) {
            pointsEarnedThisOrder = parseFloat(
              orderData.DiscountedTotal.toFixed(0)
            );
          } else {
            if (!orderData.PercentageFee) {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee;
            } else {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee +
                orderData.PercentageFee;
            }
            if (props.orderType === "Delivery") {
              total = total + orderData.DeliveryFee;
            }
            total = parseFloat(total.toFixed(0));
            pointsEarnedThisOrder = total;
          }

          const reward_points = appContext.userInfo?.rewardPoints ?? 0;
          newPointsBalance = reward_points + pointsEarnedThisOrder;

          userPointsData = {
            rewardPoints: newPointsBalance,
            userID: appContext.userInfo.userID,
          };
          await axios.put(
            `${BASE_API}UpdateCustomerRewardsPoints`,
            userPointsData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );

          newUserInfo = appContext.userInfo;
          newUserInfo.rewardPoints = parseFloat(newPointsBalance);

          newAppContext = {
            userInfo: newUserInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          newContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newContext);
          localStorage.setItem("appContext", JSON.stringify(newContext));
        }
      } else {
        newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      }

      navigate(`/${STORE_NAME_CODE}/completion`);
    } catch (error) {
      var d = JSON.stringify(orderData);
      var data = {};
      var request_data = {};
      var response_data = {};

      if (error.config) {
        data = {
          OrderID: newOrderId,
          Message: error.message + " - " + error.config.url + " - " + error.config.data
        };
      } else {
        data = {
          OrderID: newOrderId,
          Message: error.message + " - " + d,
        };
      }
      await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      if (error.request) {
        request_data = {
          OrderID: newOrderId,
          Message: "Request: " + error.request.response
        };
      }
      await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, request_data, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      if (error.response) {
        response_data = {
          OrderID: newOrderId,
          Message: "Response: " + error.response.data.Message + " - " + error.response.data.StackTraceString
        };
      }
      await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, response_data, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      await axios.post(`${BASE_API}api/Order/NewOrder`, d, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      //if the store has a rewards program
      if (storeContext.rewardsProgram === "true") {
        //if it is a user update their reward points
        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          if (orderData.DiscountedTotal) {
            pointsEarnedThisOrder = parseFloat(
              orderData.DiscountedTotal.toFixed(0)
            );
          } else {
            if (!orderData.PercentageFee) {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee;
            } else {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee +
                orderData.PercentageFee;
            }
            if (props.orderType === "Delivery") {
              total = total + orderData.DeliveryFee;
            }
            total = parseFloat(total.toFixed(0));
            pointsEarnedThisOrder = total;
          }

          const reward_points = appContext.userInfo?.rewardPoints ?? 0;
          newPointsBalance = reward_points + pointsEarnedThisOrder;

          userPointsData = {
            rewardPoints: newPointsBalance,
            userID: appContext.userInfo.userID,
          };
          await axios.put(
            `${BASE_API}UpdateCustomerRewardsPoints`,
            userPointsData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );

          newUserInfo = appContext.userInfo;
          newUserInfo.rewardPoints = parseFloat(newPointsBalance);

          newAppContext = {
            userInfo: newUserInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          newContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newContext);
          localStorage.setItem("appContext", JSON.stringify(newContext));
        }
      } else {
        newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      }
    }
  }

  async function authorizeAuthorizeNetPayment() {
    try {
      var authNetData = {
        CardNumber: cardNumber,
        ExpirationDate: expDate,
        CardCode: cvcCode,
        // Address: address,
        ZipCode: zipCode,
        ShippingCity: null,
        ShippingState: null,
        ShippingAddress: null,
        ShippingZipCode: null,
        Amount: props.finalTotal,
        Email: appContext.userInfo.email,
      };
      if (storeContext.chargePercent) {
        authNetData.Amount = props.discountedTotal;
      } else {
        authNetData.Amount = props.finalTotal;
      }
      if (props.orderType === "Delivery") {
        authNetData.ShippingCity = props.selectedAddress.city;
        authNetData.ShippingState = props.selectedAddress.stateName;
        authNetData.ShippingAddress = props.selectedAddress.streetAddress;
        authNetData.ShippingZipCode = props.selectedAddress.zipCode;
      }

      authNetData.mike = captchaToken;

      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/authorize-authorizenet-payment`,
        JSON.stringify(authNetData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      if (!dataresp.data) {
        toast.error("Your card details were not valid. Please try again.");
      }

      resetCaptcha();
      return {
        data: dataresp,
        TransactionID: dataresp.data.transactionResponse.transId,
      };
    } catch (err) {
      toast.error(err);
    }
  }

  async function createNewAuthNetCustomerPaymentProfile() {
    var authorizeNetCustomer = {
      cardNumber: cardNumber,
      expirationDate: expDate,
      cardCode: cvcCode,
      firstName: appContext.userInfo.firstName,
      lastName: appContext.userInfo.lastName,
      // address: address,
      zipCode: zipCode,
      email: "",
    };
    authorizeNetCustomer.email = appContext.userInfo.email;
    authorizeNetCustomer.customerProfileId = appContext.userInfo.customerID;

    var newData = JSON.stringify(authorizeNetCustomer);
    try {
      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/CreateCustomerPaymentProfile`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      return {
        PaymentProfileID: dataresp.data.customerPaymentProfileId,
        ProfileID: dataresp.data.customerProfileId,
      };
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function createNewAuthNetCustomer() {
    var authorizeNetCustomer = {
      cardNumber: cardNumber,
      expirationDate: expDate,
      cardCode: cvcCode,
      // address: address,
      email: appContext.userInfo.email,
      zipCode: zipCode,
      merchantCustomerID: "",
    };
    authorizeNetCustomer.merchantCustomerID = appContext.userInfo.userID;
    if (
      appContext.userInfo.role === "GuestUser" ||
      appContext.userInfo.role === "Guest"
    ) {
      authorizeNetCustomer.firstName = appContext.userInfo.firstName;
    } else {
      authorizeNetCustomer.firstName = appContext.userInfo.firstName;
      authorizeNetCustomer.lastName = appContext.userInfo.lastName;
    }

    var newData = JSON.stringify(authorizeNetCustomer);
    try {
      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/CreateNewAuthorizeNetCustomer`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      updateCustomerID(dataresp.data.customerProfileId);

      return {
        PaymentProfileID: dataresp.data.customerPaymentProfileIdList[0],
        ProfileID: dataresp.data.customerProfileId,
      };
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function updateCustomerID(profileID) {
    try {
      var newUser;
      if (appContext.userInfo.role === "GuestUser") {
        newUser = appContext.userInfo;
        newUser.customerID = profileID;
      } else {
        newUser = appContext.userInfo;
        newUser.customerID = profileID;
        await axios.put(`${BASE_API}api/User/UpdateCustomerID`, profileID, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      }
      var newAppContext = {
        userInfo: newUser,
        cart: null,
        cartItems: null,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function addPaymentMethod() {
    if (isAddingPaymentMethod === true) {
      if (previouslyActivePayment) {
        setSelectedPayment(previouslyActivePayment);
        var profileSelected = document.querySelector(
          `.payment_profile_${previouslyActivePayment.customerPaymentProfileId}`
        );
        if (profileSelected) {
          profileSelected.classList.add("Active");
        }
      }
    } else {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
      }
      if (props.customerPaymentProfiles.length === 1) {
        setPreviouslyActivePayment(props.customerPaymentProfiles[0]);
      } else if (props.customerPaymentProfiles.length > 1) {
        setPreviouslyActivePayment(selectedPayment);
      }
      setSelectedPayment();
    }
    setIsAddingPaymentMethod(!isAddingPaymentMethod);
  }

  async function setPaymentProfileForOrder(i) {
    if (!isAddingPaymentMethod) {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
        setSelectedPayment();
      } else {
        var profileSelected = document.getElementById(
          `payment_profile_${i.customerPaymentProfileId}`
        );
        profileSelected.classList.add("Active");
        setSelectedPayment(i);
      }
    } else {
      toast.error(
        "Please close the add a new payment method to select a saved payment method."
      );
    }
  }

  async function invalidItemsSubmit() {
    toast.error(
      "You have items that are invalid to order at the selected time. Please check your cart and try again!"
    );
  }

  async function togglePayLater() {
    if (props.giftCardTotalForOrder > 0 && parseFloat(props.finalTotal) === 0) {
      if (!props.customerOptsPayLater) {
        props.setGiftCardTotalForOrder(props.giftCardTotalForOrder - props.isCashAdjustment);
        props.setGiftCardBalanceAfterOrder(props.giftCardBalanceAfterOrder + props.isCashAdjustment);
      } else {
        props.setGiftCardTotalForOrder(props.giftCardTotalForOrder + props.isCashAdjustment);
        props.setGiftCardBalanceAfterOrder(props.giftCardBalanceAfterOrder - props.isCashAdjustment);
      }
    }
    props.setCustomerOptsPayLater(!props.customerOptsPayLater);
  }

  async function toggleAddPromoCode() {
    if (addGiftCardCode) {
      toast.error("You cannot add a gift card and promo code to the same order.");
    } else {
      if (props.addPromoCode === true) {
        props.setPromoTotal(0);
        props.setPromoValue(0);
        props.setPromoCodeSuccess(false);
        setIsPromoCodeExclusiveItems(false);
        props.setPromoCodeFromDB();
      }
      props.setAddPromoCode(!props.addPromoCode);
    }
  }

  const handleCheckPromoCode = async () => {
    if (props.promoValue > 0) {
      toast.error("This Promo Code has already been applied.");
    }

    const promoCodeExists = props.allPromoCodes.find(promo => promo.promoCode === props.promoCode.toUpperCase());
    props.setPromoCodeFromDB(promoCodeExists);

    if (!promoCodeExists) {
      toast.error("This Promo Code does not exist.");
    } else {
      if (promoCodeExists.promoCodePercentDiscount > 0) {
        //Percentage discount
        if (promoCodeExists.isActive === 0) {
          toast.error("This Promo Code is no longer active.");
        } else {
          toast.success(`${promoCodeExists.promoCode}: ${promoCodeExists.promoCodePercentDiscount * 100}% Promo Code Successfully Applied`);
          if (promoCodeExists.isExclusiveToSpecificItems === 1) {
            setIsPromoCodeExclusiveItems(true);
          }
          props.setPromoCodeSuccess(true);

          var orderAmount = props.discountedTotal ?? props.finalTotal;
          var promoTotalToUse = orderAmount * promoCodeExists.promoCodePercentDiscount;
          if (promoCodeExists.promoCodeValue >= orderAmount) {
            props.setPromoTotal(0);
          } else {
            props.setPromoTotal(promoTotalToUse);
          }
          props.setPromoValue(promoTotalToUse);
        }
      } else {
        //Dollar Value discount
        if (promoCodeExists.redemptionCount >= promoCodeExists.redemptionLimit) {
          toast.error("This Promo Code is no longer active.");
        } else {
          toast.success(`${promoCodeExists.promoCode}: $${promoCodeExists.promoCodeValue} Promo Code Successfully Applied`);
          if (promoCodeExists.isExclusiveToSpecificItems === 1) {
            setIsPromoCodeExclusiveItems(true);
          }
          props.setPromoCodeSuccess(true);

          var orderAmount = props.discountedTotal ?? props.finalTotal;
          if (promoCodeExists.promoCodeValue >= orderAmount) {
            props.setPromoTotal(0);
          } else {
            props.setPromoTotal(promoCodeExists.promoCodeValue);
          }
          props.setPromoValue(promoCodeExists.promoCodeValue);
        }
      }
    }
  };

  async function redeemPromoCode() {
    var promoCode = props.promoCode;
    try {
      const response = await axios.post(
        `${BASE_API}api/GiftCard/RedeemPromoCode/${promoCode}`,
        { promoCode },
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        toast.error("Promo code not found.");
      } else if (error.response?.status === 400) {
        toast.error("Promo code redemption limit reached.");
      } else {
        toast.error("An error occurred while redeeming the promo code.");
      }
    }
  }

  async function toggleAddGiftCardCode() {
    if (props.addPromoCode) {
      toast.error("You cannot add a gift card and promo code to the same order.");
    } else {
      if (addGiftCardCode === true) {
        props.setGiftCardTotalForOrder(0);
        props.setGiftCardValue(0);
        setGiftCodeSuccess(false);
      }
      setAddGiftCardCode(!addGiftCardCode);
    }
  }
  const handleCheckGiftCard = async () => {
    try {
      const response = await axios.get(`${BASE_API}api/GiftCard/GetActiveGiftCardValue/${giftCode}`, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      }
      );
      setGiftCodeSuccess(true);
      var giftValueFromDb = parseFloat(response.data.value);
      var orderAmount = props.discountedTotal ?? props.finalTotal;
      orderAmount = parseFloat(orderAmount);
      var totalForOrder;
      if (giftValueFromDb === orderAmount) {
        totalForOrder = orderAmount;
        props.setGiftCardTotalForOrder(totalForOrder);
        if (props.discountedTotal) {
          props.setGiftCardDiscountedTotalForOrder(parseFloat(props.finalTotal));
        } else {
          props.setGiftCardDiscountedTotalForOrder(0);
        }
        props.setGiftCardBalanceAfterOrder(0);
        setIsActiveGiftCardAfterOrder(false);
      } else if (giftValueFromDb > orderAmount) {
        totalForOrder = orderAmount;
        props.setGiftCardTotalForOrder(totalForOrder);
        if (props.discountedTotal) {
          props.setGiftCardDiscountedTotalForOrder(parseFloat(props.finalTotal));
        } else {
          props.setGiftCardDiscountedTotalForOrder(0);
        }
        var newbal = (giftValueFromDb - orderAmount).toFixed(2);
        newbal = parseFloat(newbal);
        props.setGiftCardBalanceAfterOrder(newbal);
        setIsActiveGiftCardAfterOrder(true);
      } else if (giftValueFromDb < orderAmount) {
        totalForOrder = giftValueFromDb;
        props.setGiftCardTotalForOrder(totalForOrder);
        props.setGiftCardBalanceAfterOrder(0);
        setIsActiveGiftCardAfterOrder(false);
      }
      props.setGiftCardValue(giftValueFromDb);
      toast.success(`$${totalForOrder.toFixed(2)} Gift Card Successfully Applied.`);
    } catch (err) {
      toast.error("Gift Card Code is Invalid.");
    }
  };
  async function updateGiftCard(isActive, giftCode, giftCardBalanceAfterOrder) {
    if (isActive) {
      isActive = 1;
    } else {
      isActive = 0;
    }
    try {
      const response = await axios.post(
        `${BASE_API}api/GiftCard/UpdateGiftCard/${isActive}/${giftCode}/${giftCardBalanceAfterOrder}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        toast.error("Gift card not found.");
      } else {
        toast.error("An error occurred while deactivating the gift card.");
      }
    }
  }
  async function getCustomerRewardsPoints() {
    try {
      const { data } = await axios.get(
        `${BASE_API}api/User/GetCustomerRewardsPoints`,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      var newUserContext = appContext.userInfo;
      newUserContext.rewardPoints = data;

      var newAppContext = {
        userInfo: newUserContext,
        cart: appContext.cart,
        cartItems: appContext.cartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="card m-1 checkout" id="payment_method">
      <div className="card-header">
        <div className="orderPage_header">Choose a payment method</div>
      </div>
      <div className="card-body">
        {storeContext.payLater && (
          <>
            <div className="add_payment_method">
              <div className="add_paymentMethodTitle">
                Pay at the time of{" "}
                {props.orderType !== "Pickup" &&
                  props.orderType !== "Delivery" ? (
                  <>Pickup/Delivery</>
                ) : (
                  <>
                    {props.orderType === "Pickup" && <>Pickup</>}
                    {props.orderType === "Delivery" && <>Delivery</>}
                  </>
                )}
              </div>
              {storeContext.nameCode === "brendaleedover" && (
                <div className="cash_only">
                  <>Cash Only</>
                </div>
              )}
              <input
                className="pay_later_checkbox"
                type="checkbox"
                checked={props.customerOptsPayLater}
                onClick={togglePayLater}
              />
            </div>
          </>
        )}

        <div className="add_payment_method_code">
          <div className="code">
            <div className="add_paymentMethodTitle">Add Gift Card Code</div>
            <input
              className="pay_later_checkbox"
              type="checkbox"
              checked={addGiftCardCode}
              onClick={toggleAddGiftCardCode}
            />
          </div>
          {addGiftCardCode && (
            <div className="mt-1" style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem' }}>
              <input
                type="text"
                className="promo-code-input"
                value={giftCode}
                onChange={(e) => setGiftCode(e.target.value)}
                placeholder="Enter Gift Card Code"
                style={{
                  flex: 1,
                  padding: '0.5rem',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  fontSize: '1rem'
                }}
              />
              <button
                className={`promo-code-apply ${giftCodeSuccess ? 'success' : ''}`}
                onClick={giftCodeSuccess ? null : handleCheckGiftCard}
                style={{
                  padding: '0.5rem 0.75rem',
                  border: 'none',
                  borderRadius: '4px',
                  backgroundColor: giftCodeSuccess ? '#4CAF50' : '#567c4b',
                  color: 'white',
                  cursor: giftCodeSuccess ? 'default' : 'pointer',
                  transition: 'background-color 0.2s'
                }}
              >
                {giftCodeSuccess ? `Applied ${props.giftCardTotalForOrder.toFixed(2)}` : 'Apply'}
              </button>
            </div>
          )}
          {props.giftCardValue > 0 && (
            <>
              {(props.giftCardValue !== props.giftCardTotalForOrder) && (
                <div className="giftCardValue">
                  <div className="giftCardRow">
                    <span>Gift Card Balance:</span>
                    <span>${props.giftCardValue.toFixed(2)}</span>
                  </div>
                  <div className="giftCardRow">
                    <span>Order Total:</span>
                    <span>${props.giftCardTotalForOrder.toFixed(2)}</span>
                  </div>
                  {(props.giftCardValue - props.giftCardTotalForOrder) > 0 && (
                    <div className="giftCardRow">
                      <span>Remaining Balance:</span>
                      <span>${(props.giftCardValue - props.giftCardTotalForOrder).toFixed(2)}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {props.allPromoCodes.length > 0 && (
          <div className="add_payment_method_code">
            <div className="code">
              <div className="add_paymentMethodTitle">Add Promo Code</div>
              <input
                className="pay_later_checkbox"
                type="checkbox"
                checked={props.addPromoCode}
                onClick={toggleAddPromoCode}
              />
            </div>
            {props.addPromoCode && (
              <div className="mt-1" style={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem' }}>
                <input
                  type="text"
                  className="promo-code-input"
                  value={props.promoCode}
                  onChange={(e) => props.setPromoCode(e.target.value)}
                  placeholder="Enter Promo Code"
                  style={{
                    flex: 1,
                    padding: '0.5rem',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    fontSize: '1rem'
                  }}
                />
                <button
                  className={`promo-code-apply ${props.promoCodeSuccess ? 'success' : ''}`}
                  onClick={props.promoCodeSuccess ? null : handleCheckPromoCode}
                  style={{
                    padding: '0.5rem 1rem',
                    border: 'none',
                    borderRadius: '4px',
                    backgroundColor: props.promoCodeSuccess ? '#4CAF50' : '#567c4b',
                    color: 'white',
                    cursor: props.promoCodeSuccess ? 'default' : 'pointer',
                    transition: 'background-color 0.2s'
                  }}
                >
                  {props.promoCodeSuccess ? 'Applied' : 'Apply'}
                </button>
              </div>
            )}
          </div>
        )}

        {!props.customerOptsPayLater && (
          <>
            <div className="add_payment_method" onClick={addPaymentMethod}>
              <div className="add_paymentMethodTitle">
                Add new payment method
              </div>
              <AiFillPlusCircle />
            </div>
            {isAddingPaymentMethod &&
              props.customerPaymentProfiles.length > 0 && (
                <form>
                  <AddPaymentMethod
                    // setCardNumber={setCardNumber}
                    // setExpDate={setExpDate}
                    // setCvcCode={setCvcCode}
                    // setAddress={setAddress}
                    // setApartmentNumber={setApartmentNumber}
                    // setZipCode={setZipCode}
                    setCardNumber={setCardNumber}
                    setExpDate={setExpDate}
                    setCvcCode={setCvcCode}
                    setZipCode={setZipCode}
                    processor={"AuthorizeNet"}
                  />
                </form>
              )}
            {props.customerPaymentProfiles.length === 0 ? (
              <form>
                <AddPaymentMethod
                  // setCardNumber={setCardNumber}
                  // setExpDate={setExpDate}
                  // setCvcCode={setCvcCode}
                  // setAddress={setAddress}
                  // setApartmentNumber={setApartmentNumber}
                  // setZipCode={setZipCode}
                  setCardNumber={setCardNumber}
                  setExpDate={setExpDate}
                  setCvcCode={setCvcCode}
                  setZipCode={setZipCode}
                  processor={"AuthorizeNet"}
                />
              </form>
            ) : (
              <>
                {props.customerPaymentProfiles.length === 1 ? (
                  <div
                    className={`payment_profile payment_profile_${props.customerPaymentProfiles[0].customerPaymentProfileId} Active`}
                  >
                    <div className="card_type">
                      {props.customerPaymentProfiles[0].payment.item.cardType}
                    </div>
                    <div className="card_number">
                      {props.customerPaymentProfiles[0].payment.item.cardNumber}
                    </div>
                  </div>
                ) : (
                  <>
                    {props.customerPaymentProfiles.map((i, index) => (
                      <div
                        key={index}
                        className="payment_profile"
                        id={`payment_profile_${i.customerPaymentProfileId}`}
                        onClick={() => setPaymentProfileForOrder(i)}
                      >
                        <div className="card_type">
                          {i.payment.item.cardType}
                        </div>
                        <div className="card_number">
                          {i.payment.item.cardNumber}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}

        <div className="d-flex justify-content-center mt-3">
          <ReCAPTCHA
            sitekey="6Lcd7YQqAAAAALmui5JnWvTjD8jYBbtsSqJ6ndi-" // Replace with your site key from Google
            ref={captchaRef}
            onChange={handleCaptchaChange}
          />
        </div>

        {props.invalidDayItems ||
          props.invalidTypeItems ||
          props.invalidTimeItems ||
          props.invalidPayInStore ? (
          <button id="submit" className="invalid" onClick={invalidItemsSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        ) : (
          <button id="submit" onClick={handleSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default CheckoutForm;
