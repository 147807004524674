import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/home/Home";
import Order from "./screens/order/Order";
import "./app.scss";
import axios from "axios";
import MenuContext from "./MenuContext";
import ApplicationContext from "./ApplicationContext";
import StoreContext from "./StoreContext";
import { getMenuCategories } from "./utils/menu/GetMenuCategories";
import {
  APPLICATION,
  BASE_API,
  STORE_NAME,
  STORE_NAME_CODE,
  setStore,
} from "./constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setApplication } from "./constants";
import { checkIfStoreIsOpen } from "./utils/store/CheckIfStoreIsOpen";
import { formatUsersCart } from "./utils/cart/FormatUsersCart";
import OrderComplete from "./screens/order/OrderComplete";
import Account from "./screens/account/Account";
import Admin from "./screens/admin/Admin";
import NotAuthorized from "./screens/admin/NotAuthorized";
import Loading from "./tools/Loading";
import Terms from "./screens/terms/Terms";
import AuthenticateModal from "./components/authenticateModal/AuthenticateModal";
import Privacy from "./screens/terms/Privacy";
import Refund from "./screens/terms/Refund";
import { checkIfStoreDeliveryIsOpen } from "./utils/store/CheckIfStoreDeliveryIsOpen";
import SystemHome from "./components/home/SystemHome";
import Rewards from "./screens/rewards/Rewards";
import ImageGalleryModal from "./components/imageGalleryModal/ImageGalleryModal";
import MyOrderPortalHome from "./components/home/MyOrderPortalHome";
import LoadingLogo from "./tools/LoadingLogo";
import ResetPasswordModal from "./components/authenticateModal/ResetPasswordModal";
import GiftCard from "./screens/giftCard/GiftCard";

function App() {
  const [applicationNotFound, setApplicationNotFound] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [menuName, setMenuName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [discountEndDate, setDiscountEndDate] = useState();

  const [detailsAreLoading, setDetailsAreLoading] = useState(true);

  const [authKey, setAuthKey] = useState("Login");
  const [showAuthenticateModal, setShowAuthenticateModal] = useState();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState();

  const [selectedItem, setSelectedItem] = useState();
  const [showItemModal, setShowItemModal] = useState(false);

  const [cartOpen, setCartOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isClosureToday, setIsClosureToday] = useState(false);
  const [noTopStoreStatusMessage, setNoTopStoreStatusMessage] = useState(false);

  const [showImageGalleryModal, setShowImageGalleryModal] = useState();

  useEffect(() => {
    const pathname = window.location.pathname;
    const store = pathname.split("/")[1];
    setApplication(store);
  }, []);

  const [storeContext, setStoreContext] = useState(
    JSON.parse(localStorage.getItem("storeContext")) || {
      open: null,
      deliveryOpen: null,
      name: null,
      nameCode: null,
      taxPercentage: null,
      chargePercent: null,
      discountPercent: null,
      menuName: null,
      categoryName: null,
      subCategoryName: null,
      hours: null,
      address: null,
      phoneNumber: null,
    }
  );

  const [menuContext, setMenuContext] = useState(
    JSON.parse(localStorage.getItem("menuContext")) || {
      items: null,
      extras: null,
      categories: null,
    }
  );

  const [appContext, setAppContext] = useState(
    JSON.parse(localStorage.getItem("appContext")) || {
      userInfo: null,
      cart: null,
      cartItems: null,
    }
  );

  const toastProps = {
    autoClose: 1250,
    position: "bottom-center",
    icon: false,
    closeButton: false,
    style: {
      width: "90vw",
      fontSize: "1.2rem",
    },
    closeOnClick: false,
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const part2 = pathname.split("/")[2];

    if (part2 !== "admin") {
      setNoTopStoreStatusMessage(true);
    }

    if (!menuContext.items || !menuContext.categories) {
      if (APPLICATION) {
        if (part2 !== "order") {
          fetchMenuData();
        }
      } else {
        setApplicationNotFound(true);
      }
    } else {
      if (part2 !== "admin" && part2 !== "order") {
        fetchMenuData();
      }
    }
  }, [APPLICATION, STORE_NAME]);

  const fetchMenuData = async () => {
    var newMenuContext;
    try {
      const response = await axios.get(`${BASE_API}api/Menu/MenuItems`, {
        headers: {
          Application: APPLICATION,
        },
      });
      const menuItems = response.data.item1;
      const menuItemExtras = response.data.item2;
      const categories = getMenuCategories(response.data.item1);
      newMenuContext = {
        items: menuItems,
        extras: menuItemExtras,
        categories: categories,
      };
      setMenuContext(newMenuContext);
      localStorage.setItem("menuContext", JSON.stringify(newMenuContext));
      setApplicationNotFound(false);
    } catch (error) {
      setApplicationNotFound(true);
      newMenuContext = {
        items: null,
        extras: null,
        categories: null,
      };
      setMenuContext(newMenuContext);
      localStorage.setItem("menuContext", JSON.stringify(newMenuContext));
    }
  };

  useEffect(() => {
    if (!storeContext.name || !STORE_NAME || !STORE_NAME_CODE) {
      if (APPLICATION) {
        fetchStoreData();
      } else {
        setApplicationNotFound(true);
      }
    }
  }, []);

  const fetchStoreData = async () => {
    var newStoreContext;
    try {
      const response = await axios.get(`${BASE_API}api/Store/GetStoreDetails`, {
        headers: {
          Application: APPLICATION,
        },
      });
      const hoursResponse = await axios.get(
        `${BASE_API}api/Hours/GetStoreHours`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const deliveryHoursResponse = await axios.get(
        `${BASE_API}api/Hours/GetDeliveryHours`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const closureResponse = await axios.get(
        `${BASE_API}api/Hours/GetStoreClosures`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      const etaResponse = await axios.get(
        `${BASE_API}api/Order/GetOrderETAs/1`,
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      // const imagesResponse = await axios.get(
      //   `${BASE_API}api/Hours/GetStoreImages`,
      //   {
      //     headers: {
      //       Application: APPLICATION,
      //     },
      //   }
      // );
      var isOpen = checkIfStoreIsOpen(hoursResponse.data, closureResponse.data);
      var isDeliveryOpen = checkIfStoreDeliveryIsOpen(
        deliveryHoursResponse.data,
        closureResponse.data
      );
      var pickupETA =
        etaResponse.data[0].pickupMin +
        " - " +
        etaResponse.data[0].pickupMax +
        " minutes";
      var deliveryETA =
        etaResponse.data[0].deliveryMin +
        " - " +
        etaResponse.data[0].deliveryMax +
        " minutes";
      newStoreContext = {
        open: isOpen,
        deliveryOpen: isDeliveryOpen,
        storeID: response.data[0].storeID,
        name: response.data[0].name,
        nameCode: response.data[0].nameCode,
        taxPercentage: response.data[0].taxPercentage,
        chargePercent: response.data[0].discountChargePercent,
        discountPercent: response.data[0].discountPercent,
        hours: hoursResponse.data,
        deliveryHours: deliveryHoursResponse.data,
        deliveryETA: deliveryETA,
        pickupETA: pickupETA,
        address: response.data[0].address,
        phoneNumber: response.data[0].phoneNumber,
        categoryName: response.data[0].categoryName,
        subCategoryName: response.data[0].subCategoryName,
        mxMerchant: response.data[0].mxMerchant,
        isActive: response.data[0].isActive,
        acceptDelivery: response.data[0].acceptDelivery,
        color: response.data[0].color,
        deliveryFee: response.data[0].deliveryFee,
        deliveryMiles: response.data[0].deliveryMiles,
        deliveryMin: response.data[0].deliveryMin,
        orderMin: response.data[0].orderMin,
        isCashAdjustment: response.data[0].isCashAdjustment,
        orderFee: response.data[0].orderFee,
        seoKeywords: response.data[0].seoKeywords,
        seoDescription: response.data[0].seoDescription,
        seoLink: response.data[0].seoLink,
        seoImage: response.data[0].seoImage,
        payLater: response.data[0].payLater,
        rewardsProgram: response.data[0].rewardsProgram,
        tzOffset: response.data[0].tzOffset,
        storeClosures: closureResponse.data,
        // storeImages: imagesResponse.data
      };
      if (!menuName) {
        setMenuName(response.data[0].menuName);
      }
      setCategoryName(response.data[0].categoryName);
      if (response.data[0].subCategoryName) {
        setSubCategoryName(response.data[0].subCategoryName);
      }
      setDiscountEndDate(response.data[0].discountEndDate);
      setStoreContext(newStoreContext);
      localStorage.setItem("storeContext", JSON.stringify(newStoreContext));

      if (newStoreContext.color) {
        // Find the element by its ID
        // const element = document.getElementById("topbar");
        const element2 = document.getElementById("cart_screen");
        // const element3 = document.getElementById("dropdown");
        // const element4 = document.getElementById("welcome");
        // const alphaValue = 0.5;
        // Parse the original color string to extract the RGB values
        // const match = newStoreContext.color.match(/\d+/g);
        // let modifiedLightColor;
        // if (match && match.length === 3) {
        //   const [red, green, blue] = match;

        //   // Create the new color string with the alpha value
        //   modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
        // }

        // if (element) {
        //   element.style.backgroundColor = newStoreContext.color;
        // }
        if (element2) {
          element2.style.backgroundColor = newStoreContext.color;
        }
        // if (element3) {
        //   element3.style.backgroundColor = newStoreContext.color;
        // }
        // if (element4 && modifiedLightColor) {
        //   element4.style.backgroundColor = modifiedLightColor;
        // }
      }

      setStore(
        newStoreContext.orderFee,
        newStoreContext.hours,
        newStoreContext.open,
        newStoreContext.name,
        newStoreContext.nameCode,
        newStoreContext.taxPercentage,
        newStoreContext.discountPercent,
        newStoreContext.categoryName,
        newStoreContext.subCategoryName
      );
      setApplicationNotFound(false);
      setDetailsAreLoading(false);
    } catch (error) {
      setApplicationNotFound(true);
      newStoreContext = {
        hours: null,
        open: null,
        storeID: null,
        name: null,
        nameCode: null,
        taxPercentage: null,
        chargePercent: null,
        discountPercent: null,
        menuName: null,
        categoryName: null,
        subCategoryName: null,
        deliveryETA: null,
        pickupETA: null,
        address: null,
        phonenumber: null,
        mxMerchant: null,
        color: null,
        deliveryMiles: null,
        deliveryMin: null,
        orderMin: null,
        isCashAdjustment: null,
      };
      setStoreContext(newStoreContext);
      localStorage.setItem("storeContext", JSON.stringify(newStoreContext));
    }
  };

  useEffect(() => {
    var isOpen = checkIfStoreIsOpen();
    var isDeliveryOpen = checkIfStoreDeliveryIsOpen();
    var newStore = storeContext;
    newStore.open = isOpen;
    newStore.deliveryOpen = isDeliveryOpen;
    setStore(newStore);
    localStorage.setItem("storeContext", JSON.stringify(newStore));
  }, []);

  useEffect(() => {
    async function fetchCartData() {
      try {
        const response = await axios.get(`${BASE_API}api/Cart/GetMyCart`, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        });
        if (response.data.Cart.length < 1) {
          return;
        }
        var results = formatUsersCart(response);
        var newPrice = 0;
        for (var a = 0; a < results.loaded_cartItems.length; a++) {
          newPrice = newPrice + results.loaded_cartItems[a].ItemTotalPrice;
        }
        try {
          await axios.put(
            `${BASE_API}api/Cart/UpdateCartPrice/${newPrice}`,
            {
              newPrice,
            },
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );
        } catch (error) {
          console.log(error);
        }

        var newAppContext = {
          userInfo: appContext.userInfo,
          cart: results.loaded_cart,
          cartItems: results.loaded_cartItems,
        };
        setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      } catch (error) {
        if (error.response.status === 401) {
          newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
          toast.error("Your session has expired please login again.");
        }
      }
    }

    if (appContext.userInfo && !appContext.cart) {
      if (
        APPLICATION &&
        (appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin")
      ) {
        fetchCartData();
      }
    }
  }, [appContext]);

  useEffect(() => {
    if (STORE_NAME_CODE && appContext.userInfo) {
      if (STORE_NAME_CODE !== appContext.userInfo.storeName) {
        var newAppContext = {
          userInfo: null,
          cart: null,
          cartItems: null,
        };
        setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      }
    }
  }, [STORE_NAME_CODE, appContext.userInfo]);

  // Helper function to get today's date in the format {"YYYY-MM-DDT00:00:00"}
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = (today.getMonth() + 1).toString().padStart(2, "0");
    const dd = today.getDate().toString().padStart(2, "0");
    return `${yyyy}-${mm}-${dd}T00:00:00`;
  };

  useEffect(() => {
    if (storeContext.storeClosures) {
      const todayDate = getTodayDate();
      const storeClosures = storeContext.storeClosures.map(
        (closure) => closure.dateClosed
      );
      setIsClosureToday(storeClosures.includes(todayDate));
    }
  }, []);

  return (
    <>
      {noTopStoreStatusMessage && (
        <>
          {STORE_NAME_CODE &&
            storeContext.isActive !== "true" &&
            !showAuthenticateModal && (
              <>
                <div className="desktop store_is_not_active fixed-top d-flex justify-content-center">
                  Online ordering temporarily unavailable. Please come back soon and
                  try again.
                </div>
                <div className="mobile store_is_not_active fixed-top d-flex justify-content-center">
                  Online ordering temporarily unavailable. <br /> Please come back
                  soon and try again.
                </div>
              </>
            )}
          {isClosureToday && (
            <>
              <div className="desktop store_is_not_active fixed-top d-flex justify-content-center">
                Sorry we are closed for the day. Please come back tomorrrow and try
                again.
              </div>
              <div className="mobile store_is_not_active fixed-top d-flex justify-content-center">
                Sorry we are closed for the day. <br /> Please come back tomorrrow
                and try again.
              </div>
            </>
          )}
          {storeContext.open === "Closed" && !isClosureToday && (
            <>
              <div className="desktop store_is_not_active fixed-top d-flex justify-content-center">
                {storeContext.acceptDelivery === "true" ? (
                  <>
                    We are closed right now, but you can order for later. Choose a pickup or delivery time at checkout.
                  </>
                ) : (
                  <>
                    We are closed right now, but you can order for later. Choose a pickup time at checkout.
                  </>
                )}
              </div>
              <div className="mobile store_is_not_active fixed-top d-flex justify-content-center">
                {storeContext.acceptDelivery === "true" ? (
                  <>
                    We are closed right now, but you can order for later. Choose a pickup or delivery time at checkout.
                  </>
                ) : (
                  <>
                    We are closed right now, but you can order for later. Choose a pickup time at checkout.
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
      {showAuthenticateModal && (
        <AuthenticateModal
          appContext={appContext}
          storeContext={storeContext}
          showAuthenticateModal={showAuthenticateModal}
          setShowAuthenticateModal={setShowAuthenticateModal}
          showResetPasswordModal={showResetPasswordModal}
          setShowResetPasswordModal={setShowResetPasswordModal}
          authKey={authKey}
          setAuthKey={setAuthKey}
          setAppContext={setAppContext}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          appContext={appContext}
          storeContext={storeContext}
          showResetPasswordModal={showResetPasswordModal}
          setShowResetPasswordModal={setShowResetPasswordModal}
          setAppContext={setAppContext}
        />
      )}

      {applicationNotFound ? (
        // <SystemHome />
        <MyOrderPortalHome />
      ) : (
        <>
          {detailsAreLoading ? (
            <div className="loading_details" id="loading_details">
              <LoadingLogo />
            </div>
          ) : (
            <StoreContext.Provider value={storeContext}>
              <ApplicationContext.Provider value={appContext}>
                <MenuContext.Provider value={menuContext}>
                  <div className="app">
                    <ToastContainer
                      position="bottom-center"
                      limit={1}
                      {...toastProps}
                    />
                    <BrowserRouter>
                      <Routes>
                        <Route path="/our_stores" element={<SystemHome />} />
                        <Route
                          path="/:storeParam"
                          element={
                            <Home
                              showItemModal={showItemModal}
                              setShowItemModal={setShowItemModal}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              detailsAreLoading={detailsAreLoading}
                              menuName={menuName}
                              setMenuName={setMenuName}
                              categoryName={categoryName}
                              setCategoryName={setCategoryName}
                              subCategoryName={subCategoryName}
                              setSubCategoryName={setSubCategoryName}
                              discountEndDate={discountEndDate}
                              setAppContext={setAppContext}
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              showAuthenticateModal={showAuthenticateModal}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              cartOpen={cartOpen}
                              setCartOpen={setCartOpen}
                              isDisabled={isDisabled}
                              setIsDisabled={setIsDisabled}
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/terms"
                          element={
                            <Terms
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/privacy"
                          element={
                            <Privacy
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/refund"
                          element={
                            <Refund
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/giftCards"
                          element={
                            <GiftCard
                              authKey={authKey}
                              setAuthKey={setAuthKey}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              setAppContext={setAppContext}
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/order"
                          element={
                            <Order
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              selectedAddress={selectedAddress}
                              setSelectedAddress={setSelectedAddress}
                              discountEndDate={discountEndDate}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/completion"
                          element={
                            <OrderComplete
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/account"
                          element={
                            <Account
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                            />
                          }
                        />
                        <Route
                          path="/:storeParam/rewards"
                          element={
                            <Rewards
                              isDisabled={isDisabled}
                              setIsDisabled={setIsDisabled}
                              showItemModal={showItemModal}
                              setShowItemModal={setShowItemModal}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              setAuthKey={setAuthKey}
                              setAppContext={setAppContext}
                              setShowAuthenticateModal={
                                setShowAuthenticateModal
                              }
                              cartOpen={cartOpen}
                              setCartOpen={setCartOpen}
                              detailsAreLoading={detailsAreLoading}
                            />
                          }
                        />
                        {appContext.userInfo &&
                          appContext.userInfo.role === "Admin" ? (
                          <>
                            <Route
                              path="/:storeParam/admin"
                              element={
                                <Admin
                                  setAuthKey={setAuthKey}
                                  menuContext={menuContext}
                                  setAppContext={setAppContext}
                                  setStoreContext={setStoreContext}
                                  setShowAuthenticateModal={
                                    setShowAuthenticateModal
                                  }
                                />
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Route
                              path="/:storeParam/admin"
                              element={
                                <NotAuthorized
                                  setAuthKey={setAuthKey}
                                  setAppContext={setAppContext}
                                  setShowAuthenticateModal={
                                    setShowAuthenticateModal
                                  }
                                />
                              }
                            />
                          </>
                        )}
                      </Routes>
                    </BrowserRouter>
                  </div>
                </MenuContext.Provider>
              </ApplicationContext.Provider>
            </StoreContext.Provider>
          )}
        </>
      )}
    </>
  );
}

export default App;
