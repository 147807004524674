import React, { useContext } from "react";
import ApplicationContext from "../../ApplicationContext";
import { DISCOUNT_PERCENTAGE, ORDER_FEE } from "../../constants";
import StoreContext from "../../StoreContext";
import { MdCheckCircle } from "react-icons/md";
import { MdRadioButtonUnchecked } from "react-icons/md";

function OrderDetails(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  function formatOrderTime(orderTimeString) {
    if (!orderTimeString) return ""; // return empty string if no time is provided
    var [startTime, endTime] = orderTimeString.split("-");

    var formattedStartTime = formatTime(startTime);
    var formattedEndTime = formatTime(endTime);

    return formattedStartTime + ' - ' + formattedEndTime;
  }

  function formatTime(timeString) {
    var [hours, minutes] = timeString.split(":");

    var ampm = hours >= 12 ? 'p.m.' : 'a.m.';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    hours = hours < 10 ? '' + hours : hours;
    minutes = minutes < 10 ? '' + minutes : minutes;

    return hours + ':' + minutes + ' ' + ampm.toLowerCase();
  }

  const formatOrderDays = (orderDays) => {
    const daysArray = orderDays.split('||');

    if (daysArray.length > 1) {
      const lastDay = daysArray.pop();
      return `${daysArray.join(', ')} and ${lastDay}`;
    }

    return daysArray[0];
  };

  return (
    <div className="card m-1 orderDetails">
      <div className="card-header">
        <div className="orderPage_header">Order Details</div>
      </div>
      <div className="card-body">
        <div className="details_orderItems">
          {appContext.cartItems?.map((i, index) => (
            <>
              <div className="orderPage_item" key={index}>
                <div className="orderPage_itemQuantity">{i.ItemQuantity}</div>
                <div className="orderPage_itemDetails">
                  {i.RewardCost > 0 && <div>{i.RewardCost} Reward Points</div>}
                  <div>{i.ItemName}</div>
                  {i.SizeCode && <div>({i.SizeCode})</div>}
                  {i.Extras.map((j, extraIndex) => (
                    <div key={`${index}-${extraIndex}`}>
                      {"+ " + j.ExtraName + " "}
                      {j.ExtraDescription === "FirstHalf" && (<span className="orderPage_itemPizzaHalf">(1st Half)</span>)}
                      {j.ExtraDescription === "SecondHalf" && (<span className="orderPage_itemPizzaHalf">(2nd Half)</span>)}
                    </div>
                  ))}
                  {i.ItemSpecialRequests && (
                    <div>{'"' + i.ItemSpecialRequests + '"'}</div>
                  )}
                </div>
                <div className="orderPage_itemTotalContainer">
                  <div className="orderPage_itemTotal">
                    {"$" + i.ItemPrice.toFixed(2)}
                  </div>
                  {i.SizeCode && (
                    <div className="orderPage_itemTotal blank">-</div>
                  )}
                  {i.Extras.map((j) => (
                    <div className="orderPage_itemTotal">
                      {"$" + j.ExtraPrice.toFixed(2)}
                    </div>
                  ))}
                </div>
              </div>
              {i.OrderDay && (
                <div className="unavailable card text-center">
                  {!props.invalidDayItems ? (
                    <MdCheckCircle className="checkbox green-check" />
                  ) : (
                    <MdRadioButtonUnchecked className="checkbox" />
                  )}
                  This item is only available on {formatOrderDays(i.OrderDay)}
                </div>
              )}
              {i.OrderType && (
                <div className="unavailable card text-center">
                  {!props.invalidTypeItems ? (
                    <MdCheckCircle className="checkbox green-check" />
                  ) : (
                    <MdRadioButtonUnchecked className="checkbox" />
                  )}
                  This item is only available for {i.OrderType} orders
                </div>
              )}
              {i.OrderTime && (
                <div className="unavailable card text-center">
                  {!props.invalidTimeItems ? (
                    <MdCheckCircle className="checkbox green-check" />
                  ) : (
                    <MdRadioButtonUnchecked className="checkbox" />
                  )}
                  This item is only available from{" "}
                  {formatOrderTime(i.OrderTime)}{" "}
                </div>
              )}
              {i.PayInStore === "true" && (
                <div className="unavailable card text-center">
                  {!props.invalidPayInStore ? (
                    <MdCheckCircle className="checkbox green-check" />
                  ) : (
                    <MdRadioButtonUnchecked className="checkbox" />
                  )}
                  <div className="cash_in_store">
                    <div>Cash is required for this item.</div>
                    <div>Select to pay at{" "}
                      {!props.orderType ? (
                        <>
                          {storeContext.acceptDelivery === "true" ?
                            (
                              <>Pickup/Delivery{" "}</>
                            ) : (
                              <>Pickup{" "}</>
                            )}
                        </>
                      ) : (
                        <>
                          {props.orderType}{" "}
                        </>
                      )}
                      below.
                    </div>
                  </div>
                </div>
              )}
              {props.promoCodeFromDB && (
                <>
                  {props.promoCode && !i.ExclusivePromoCode && props.addPromoCode && props.promoCodeFromDB.isExclusiveToSpecificItems === 1 && (
                    <div className="unavailable card text-center">
                      This item is not available for Promo Code: {props.promoCode}
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        </div>

        {storeContext.chargePercent ? (
          <>
            <div className="details_orderCharges">
              <div className="details_charges">
                <div className="details_chargeType">Subtotal</div>
                <>
                  <div className="discount">
                    <div>{DISCOUNT_PERCENTAGE} off</div>
                  </div>
                  <div className="details_price_edited">
                    <s>{"$" + appContext.cart.CartPrice.toFixed(2)}</s>
                  </div>
                  <div
                    className="details_price_edited"
                    Style={"font-weight:700;"}
                  >
                    {"$" + props.discountedSubtotal}
                  </div>
                </>
              </div>
              <div className="details_charges">
                <div className="details_chargeType">Tax</div>
                <>
                  <div className="discount">
                    <div>{DISCOUNT_PERCENTAGE} off</div>
                  </div>
                  <div className="details_price_edited">
                    <s>{"$" + (props.taxAmount * 1).toFixed(2)}</s>
                  </div>
                  <div
                    className="details_price_edited"
                    Style={"font-weight:700;"}
                  >
                    {"$" + props.discountedTax}
                  </div>
                </>
              </div>
              {props.tipAmount && (
                <div className="details_charges">
                  <div className="details_chargeType">
                    {props.tipAmount && props.tipAmount.type
                      ? "Tip - " + props.tipAmount.type
                      : "Tip"}
                  </div>
                  <div className="details_price">
                    {"$" + props.tipAmount.amount}
                  </div>
                </div>
              )}
              <div className="details_charges">
                <div className="details_chargeType">Service Fee</div>
                <div className="details_price">
                  {"$" + ORDER_FEE.toFixed(2)}
                </div>
              </div>
              {props.orderType === "Delivery" && (
                <div className="details_charges">
                  <div className="details_chargeType">Delivery Fee</div>
                  <div className="details_price">
                    {"$" + props.deliveryFee.toFixed(2)}
                  </div>
                </div>
              )}
              {storeContext.isCashAdjustment > 0 && props.customerOptsPayLater === false && (
                <div className="details_charges">
                  <div className="details_chargeType">Card Processing Fee</div>
                  <div className="details_price">
                    {"$" + props.isCashAdjustment}
                  </div>
                </div>
              )}
              {props.giftCardValue > 0 && (
                <div className="details_charges">
                  <div className="details_chargeType">Gift Card Discount</div>
                  <div className="d-flex">
                    {/* {props.giftCardValue.toFixed(2) != props.giftCardTotalForOrder.toFixed(2) && (
                      <div className="details_priceSmall mx-2">
                        {"-$" + props.giftCardValue.toFixed(2)}
                      </div>
                    )} */}
                    {/* <div className="details_priceSmall mx-2">
                      {"-$" + props.giftCardValue.toFixed(2)}
                    </div> */}
                    <div className="details_price">
                      {"-$" + props.giftCardTotalForOrder.toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
              {props.promoValue > 0 && (
                <div className="details_charges">
                  <div className="details_chargeType">Promo Code Discount</div>
                  <div className="details_price">
                    {"-$" + props.promoValue.toFixed(2)}
                  </div>
                </div>
              )}
              <div className="details_finalCharge details_charges">
                <div className="details_chargeType">Final Total</div>
                <>
                  <div className="details_price_edited">
                    <s>{"$" + props.finalTotal}</s>
                  </div>
                  <div
                    className="details_price_edited"
                    Style={"font-weight:700;"}
                  >
                    {"$" + props.discountedTotal}
                  </div>
                </>
              </div>
              <div className="saving_container d-flex justify-content-center">
                You are saving:
                <b className="mx-1">
                  ${(props.finalTotal - props.discountedTotal).toFixed(2)}
                </b>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="details_orderCharges">
              <div className="details_charges">
                <div className="details_chargeType fs-5">Subtotal</div>
                <div className="details_price fs-5">
                  {"$" + appContext.cart.CartPrice}
                </div>
              </div>
              <div className="details_charges">
                <div className="details_chargeType fs-5">Tax</div>
                <div className="details_price fs-5">
                  {"$" + props.taxAmount}
                </div>
              </div>
              {props.tipAmount && (
                <div className="details_charges">
                  <div className="details_chargeType fs-5">
                    {props.tipAmount && props.tipAmount.type
                      ? "Tip - " + props.tipAmount.type
                      : "Tip"}
                  </div>
                  <div className="details_price fs-5">
                    {"$" + props.tipAmount.amount}
                  </div>
                </div>
              )}
              <div className="details_charges">
                <div className="details_chargeType fs-5">Service Fee</div>
                <div className="details_price fs-5">
                  {"$" + ORDER_FEE.toFixed(2)}
                </div>
              </div>
              {props.orderType === "Delivery" && (
                <>
                  {props.deliveryFee ? (
                    <>
                      <div
                        className="details_charges"
                      >
                        <div className="details_chargeType fs-5">
                          Delivery Fee
                        </div>
                        <div className="details_price fs-5">
                          ${props.deliveryFee.toFixed(2)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="details_charges"
                      >
                        <div className="details_chargeType fs-5">
                          Delivery Fee
                        </div>
                        <div className="details_price fs-5">FREE</div>
                      </div>
                    </>
                  )}
                </>
              )}
              {storeContext.isCashAdjustment > 0 && props.customerOptsPayLater === false && (
                <div className="details_charges">
                  <div className="details_chargeType">Card Processing Fee</div>
                  <div className="details_price">
                    {"$" + props.isCashAdjustment}
                  </div>
                </div>
              )}
              {props.giftCardValue > 0 && (
                <div className="details_charges">
                  <div className="details_chargeType">Gift Card Discount</div>
                  <div className="d-flex">
                    {props.giftCardValue.toFixed(2) != props.giftCardTotalForOrder.toFixed(2) && (
                      <div className="details_priceSmall mx-2">
                        {"-$" + props.giftCardValue.toFixed(2)}
                      </div>
                    )}
                    <div className="details_price">
                      {"-$" + props.giftCardTotalForOrder.toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
              {props.promoValue > 0 && (
                <div className="details_charges">
                  <div className="details_chargeType">Promo Code Discount</div>
                  <div className="details_price">
                    {"-$" + props.promoValue.toFixed(2)}
                  </div>
                </div>
              )}
              <div className="details_finalCharge">
                <div className="details_chargeType fs-5">Final Total</div>
                <div className="details_price fs-5">
                  {"$" + props.finalTotal}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
