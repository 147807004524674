const stores = [
    {
        name: "Frank's Pizza",
        image: "https://i.ibb.co/gRTXYt6/pizza.jpg",
        address: "5762 Berkshire Valley Rd, Oak Ridge, NJ 07438",
        latitude: "41.037475",
        longitude: "-74.504721",
        orderLink: "https://myorderportal.com/frankspizzaoakridge"
    },
    {
        name: "Somerset Pizza",
        image: "https://i.ibb.co/wYmyWPL/pizzas.jpg",
        address: "147 Pierce St, Somerset, NJ 08873",
        latitude: "40.5357408",
        longitude: "-74.5393893",
        orderLink: "https://myorderportal.com/somersetpizza"
    },
    {
        name: "Brenda Lee Restaurant",
        image: "https://i.ibb.co/KDXW9rx/tacos.jpg",
        address: "15 E Blackwell St, Dover, NJ 07801",
        latitude: "40.884348",
        longitude: "-74.557658",
        orderLink: "https://myorderportal.com/brendaleedover"
    },
    {
        name: "Little Italy Brick Oven",
        image: "https://i.ibb.co/wLmZrVv/chick-parm.jpg",
        address: "459 NJ-31, Washington Twp, NJ 07882",
        latitude: "40.7207385",
        longitude: "-74.9712099",
        orderLink: "https://myorderportal.com/littleitalybrickoven"
    },
    {
        name: "Annabel's Pizza & Italian Restaurant",
        image: "https://i.ibb.co/WkzJFxG/salmon-and-broccoli.jpg",
        address: "6 Main St, Unionville, NY 10988",
        latitude: "41.3020822",
        longitude: "-74.5622522",
        orderLink: "https://myorderportal.com/annabelspizzaunionville"
    },
    {
        name: "Gabrielle's Pizza",
        image: "https://i.ibb.co/zGB8985/pizza1.png",
        address: "227 Newton Sparta Rd #1, Newton, NJ 07860",
        latitude: "41.0382397",
        longitude: "-74.7036149",
        orderLink: "https://myorderportal.com/gabriellespizza"
    },
    {
        name: "Pizza Rustica",
        image: "https://i.ibb.co/p21gXLF/pizza1.jpg",
        address: "25 W Main St, Rockaway, NJ 07866",
        latitude: "40.9009941",
        longitude: "-74.5141316",
        orderLink: "https://myorderportal.com/pizzarusticarockaway"
    },
    {
        name: "La Casa Del Taco",
        image: "https://i.ibb.co/DVsN8WQ/tacos.jpg",
        address: "1965 Rt 57, Allen Rd, Hackettstown, NJ 07840",
        latitude: "40.8236764",
        longitude: "-74.8360437",
        orderLink: "https://myorderportal.com/lacasadeltaco"
    },
    {
        name: "Frank's Pizza",
        image: "https://i.ibb.co/nQRWpMC/penne-vodka.jpg",
        address: "152 NJ-94, Blairstown, NJ 07825",
        latitude: "40.98309",
        longitude: "-74.9951008",
        orderLink: "https://myorderportal.com/frankspizzablairstown"
    },
    {
        name: "Rivoli Pizzeria",
        image: "https://i.ibb.co/0C8NL7C/platter.jpg",
        address: "2193 Morris Ave, Union, NJ 07083",
        latitude: "40.69851",
        longitude: "-74.27876",
        orderLink: "https://myorderportal.com/rivolipizzeriaunion"
    },
    {
        name: "Vincenzo's Pizza",
        image: "https://i.ibb.co/ckH6PFF/Chicken-Francese.jpg",
        address: "3 Netcong Rd, Budd Lake, NJ 07828",
        latitude: "40.8835126",
        longitude: "-74.7310823",
        orderLink: "https://myorderportal.com/vincenzopizzabuddlake"
    },
    {
        name: "Roman Pizza & Restaurant",
        image: "https://i.ibb.co/8z8L7Dj/Roman-Pizza-garlicknots.jpg",
        address: "124A NJ-94, Blairstown, NJ 07825",
        latitude: "40.987962327869795",
        longitude: "-74.9812803151336",
        orderLink: "https://myorderportal.com/romanpizzablairstown"
    },
    {
        name: "Frank's Trattoria",
        image: "https://i.ibb.co/VMx8Wyc/margarita-pizza.jpg",
        address: "518 Old Post Rd, Edison, NJ 08817",
        latitude: "40.516070",
        longitude: "-74.376720",
        orderLink: "https://myorderportal.com/frankspizzaedison"
    },
    {
        name: "Uncle Sal's Pizza II",
        image: "https://i.ibb.co/ygLwGSy/sausage-parm-sub.jpg",
        address: "147 Woodport Rd, Sparta, NJ 07871",
        latitude: "41.026870",
        longitude: "-74.631060",
        orderLink: "https://myorderportal.com/unclesalssparta"
    },
    {
        name: "Little Italy Pizzeria",
        image: "https://i.ibb.co/MPggRB6/thumbnail-IMG-20241.jpg",
        address: "539 US-46, Belvidere, NJ 07823",
        latitude: "40.843290",
        longitude: "-75.054060",
        orderLink: "https://myorderportal.com/littleitalybelvidere"
    },
    {
        name: "Villa Capri Pizzeria & Restaurant",
        image: "https://i.ibb.co/Zh1HpC6/panini.jpg",
        address: "27 Hampton House Rd, Newton, NJ 07860",
        latitude: "41.072540",
        longitude: "-74.740547",
        orderLink: "https://myorderportal.com/villacaprinewton"
    },
    {
        name: "La Cocina de Maria",
        image: "https://i.ibb.co/ZVjDS07/food.jpg",
        address: "70 Main St, Netcong, NJ 07857",
        latitude: "40.89851058676258",
        longitude: "-74.7062351028396",
        orderLink: "https://myorderportal.com/lacocinademaria"
    },
    {
        name: "Jefry's Pizza",
        image: "https://i.ibb.co/6NWDs92/upsidedown-pizza.jpg",
        address: "355 Parsippany Rd, Parsippany, NJ 07054",
        latitude: "40.85438465385289",
        longitude: "-74.42688351858429",
        orderLink: "https://myorderportal.com/jefryspizzaparsippany"
    },
    {
        name: "New York Pizza & Italian Restaurant",
        image: "https://i.ibb.co/fGxs87c/2023-11-28.jpg",
        address: "2848 S Locust St, Grand Island, NE 68801",
        latitude: "40.89335104875912",
        longitude: "-98.34058275116733",
        orderLink: "https://myorderportal.com/newyorkpizzagrandisland"
    },
    {
        name: "Pizza Express",
        image: "https://i.ibb.co/tMQtJmNN/pizza2.jpg",
        address: "187 NJ-94, Blairstown, NJ 07825",
        latitude: "40.97873252614162",
        longitude: "-75.00462424881141",
        orderLink: "https://myorderportal.com/pizzaexpressblairstown"
    },
    {
        name: "Mondo Pizza",
        image: "https://i.ibb.co/qL1tM9kS/mondo-pizza.jpg",
        address: "540 NJ-10, Randolph, NJ 07869",
        latitude: "40.86309684589819",
        longitude: "-74.57271568131883",
        orderLink: "https://myorderportal.com/mondopizzarandolph"
    },
    {
        name: "Uncle Sal's Italian Villa",
        image: "https://i.ibb.co/XxsB0H7G/pizza-Background.png",
        address: "375 US-46 #4, Budd Lake, NJ 07828",
        latitude: "40.86105834430478",
        longitude: "-74.75329554721216",
        orderLink: "https://myorderportal.com/unclesalsbuddlake"
    },
];

export default stores;