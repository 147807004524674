import React, { useContext, useEffect, useState } from "react";
import MenuContext from "../../MenuContext";

function MenuItems(props) {
  const menuContext = useContext(MenuContext);

  const [menuItems, setMenuItems] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState();

  useEffect(() => {
    if (menuContext.items) {
      var menuitemsArray = [];
      for (var i = 0; i < menuContext.items.length; i++) {
        var itemName = menuContext.items[i].itemName;
        if (menuContext.items[i].itemSubCategory) {
          if (
            menuContext.items[i].itemSubCategory === props.subCategoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        } else {
          if (
            menuContext.items[i].itemCategory === props.categoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        }
      }
      setMenuItems(menuitemsArray);
    }
  }, [props]);

  async function toggleItemModal(item) {
    item.active = "Active";
    props.setSelectedItem(item);
    props.setShowItemModal(true);
  }

  let cat;
  useEffect(() => {
    if (props.menuName) {
      cat = menuContext.categories[props.menuName];
      if (cat) {
        for (var i = 0; i < cat.length; i++) {
          if (props.categoryName === cat[i].category) {
            if (cat[i].subcategories.length > 0) {
              for (var j = 0; j < cat[i].subcategories.length; j++) {
                if (props.subCategoryName === cat[i].subcategories[j].subCategoryName) {
                  setCategoryDescription(cat[i].subcategories[j].subCategoryDescription);
                }
              }
            } else {
              setCategoryDescription(cat[i].categoryDescription);
            }
          }
        }
      }
    }
  }, [props]);

  function formatDays(days) {
    const dayArray = days.split("||");
    return dayArray.join(", ").replace(/, ([^,]*)$/, " and $1");
  }

  function formatToUSTime(timeRange) {
    const convertTime = (time) => {
      let [hours, minutes] = time.split(':').map(Number);
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert 0 or 12+ hour to 12-hour format
      return `${hours}:${String(minutes).padStart(2, '0')} ${period}`;
    };

    const [startTime, endTime] = timeRange.split('-');
    return `${convertTime(startTime)} - ${convertTime(endTime)}`;
  }

  return (
    <div className="menu_items">
      {categoryDescription && (
        <div className="categoryDescription">
          {categoryDescription}
        </div>
      )}
      {menuItems?.map((i, index) => (
        <div
          key={index}
          className="card"
          onClick={() => toggleItemModal(i)}
        >
          <div className="px-4 py-2">
            <h4 className="fw-bold itemName">{i.itemName}</h4>
            {i.itemDescription && (
              <p className="mb-1 itemDescription"><em>{i.itemDescription}</em></p>
            )}
            {i.orderDay && i.orderTime ? (
              <div className="mb-1 itemDays">Only Available: <em>{formatDays(i.orderDay)} from {formatToUSTime(i.orderTime)}</em></div>
            ) : (
              <>
                {i.orderDay && (
                  <div className="mb-1 itemDays">Only Available: <em>{formatDays(i.orderDay)}</em></div>
                )}
                {i.orderTime && (
                  <div className="mb-1 itemDays">Only Available: <em>{formatToUSTime(i.orderTime)}</em></div>
                )}
              </>
            )}
            {i.maxPerOrder && (
              <div className="mb-1 itemDays">Max Per Order: <em>{i.maxPerOrder}</em></div>
            )}
            <p className="mb-0 fw-bold itemPrice">
              {"$" + i.itemPrice.toFixed(2)}
            </p>
          </div>
        </div>
      ))
      }
    </div>
  );
}

export default MenuItems;
