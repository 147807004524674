import React, { useContext, useState } from "react";
import ApplicationContext from "../../ApplicationContext";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../constants";
import { toast } from "react-toastify";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import { updateCartValues } from "../../utils/cart/UpdateCartValues";

function RecentOrders(props) {
  const appContext = useContext(ApplicationContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  async function viewDetails(order) {
    var orderClicked = document.getElementById(`${order.orderID}_body`);
    if (orderClicked.classList.contains("collapse")) {
      orderClicked.classList.remove("collapse");
    } else {
      orderClicked.classList.add("collapse");
    }
  }

  async function addItemsToCart(order) {
    //ADD EACH ITEM FROM THE ORDER
    //ITEMS
    for (var i = 0; i < order.items.length; i++) {
      var userCartData = {
        CartID: appContext.userInfo.userID,
        OrderType: order.orderType,
        Item: {
          Extras: [],
        },
      };
      var itemGuid = CreateGuid();
      userCartData.Item.CartItemID = itemGuid;
      userCartData.Item.CartItem_CartID = appContext.userInfo.userID;
      userCartData.Item.ItemQuantity = order.items[i].itemQuantity;
      userCartData.Item.ItemSpecialRequests = order.items[i].itemSpecialRequests;
      userCartData.Item.ItemPrice = order.items[i].itemPrice;
      userCartData.Item.ItemName = order.items[i].itemName;
      userCartData.Item.CartItem_MenuItemID = order.items[i].menuItemID;
      userCartData.Item.ItemCategory = order.items[i].itemCategory;
      userCartData.Item.ItemSubCategory = order.items[i].itemSubCategory;
      userCartData.Item.ItemDescription = order.items[i].itemDescription;
      userCartData.Item.ItemOptions = order.items[i].itemOptions;
      userCartData.Item.MenuID = order.items[i].menuID;
      userCartData.Item.OrderType = order.items[i].orderType;
      userCartData.Item.OrderDay = order.items[i].orderDay;
      userCartData.Item.OrderTime = order.items[i].orderTime;
      userCartData.Item.PayInStore = order.items[i].payInStore;
      userCartData.Item.Image = order.items[i].image;
      userCartData.Item.MaxPerOrder = order.items[i].maxPerOrder;
      userCartData.Item.ExclusivePromoCode = order.items[i].exclusivePromoCode;

      if (order.items[i].itemSize) {
        userCartData.Item.SizeCode = order.items[i].itemSize;
      } else {
        userCartData.Item.SizeCode = null;
      }

      //ADD EXTRAS
      var extrasTotal = 0.0;
      var formattedExtras = [];
      for (var p = 0; p < order.items[i].extras.length; p++) {
        extrasTotal = extrasTotal + order.items[i].extras[p].extraPrice;
        if (order.items[i].extras[p].extraDescription === "FirstHalf") {
          order.items[i].extras[p].pizzaHalfCode = "(1st Half)";
        } else if (order.items[i].extras[p].extraDescription === "SecondHalf") {
          order.items[i].extras[p].pizzaHalfCode = "(2nd Half)";
        } else {
          order.items[i].extras[p].pizzaHalfCode = null;
        }
        formattedExtras[p] = {
          Active: order.items[i].extras[p].active,
          CartExtra_CartID: order.items[i].extras[p].cartExtra_CartID,
          CartExtra_CartItemID: order.items[i].extras[p].cartExtra_CartItemID,
          ExtraCode: order.items[i].extras[p].extraCode,
          ExtraDescription: order.items[i].extras[p].extraDescription,
          ExtraName: order.items[i].extras[p].extraName,
          ExtraPrice: order.items[i].extras[p].extraPrice,
          ExtraSize: order.items[i].extras[p].extraSize,
          ExtraType: order.items[i].extras[p].extraType,
          Extras: order.items[i].extras[p].extras,
          CartExtra_MenuItemExtraID: order.items[i].extras[p].menuItemExtraID,
          PizzaHalfCode: order.items[i].extras[p].pizzaHalfCode,
        };
        userCartData.Item.Extras.push(formattedExtras[p]);
      }

      //ITEM TOTAL
      var totalPrice =
        (order.items[i].itemPrice + extrasTotal) * order.items[i].itemQuantity;
      let numDouble = totalPrice.toFixed(2);
      userCartData.Item.ItemTotalPrice = parseFloat(numDouble);

      var newAppContext;
      //ADD IT ALL TO CART
      try {
        await axios.post(
          `${BASE_API}api/Cart/AddToCart/${order.items[i].menuItemID}`,
          userCartData,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        var itemExists = {
          itemExists: false,
        };
        //update cart with newItem and place in updatedCart
        var updatedCart;
        if (!newAppContext) {
          updatedCart = updateCartValues(
            appContext,
            userCartData.Item,
            itemExists
          );
        } else {
          updatedCart = updateCartValues(
            newAppContext,
            userCartData.Item,
            itemExists
          );
        }
        var newItems;
        if (newAppContext) {
          newItems = newAppContext.cartItems || [];
        } else {
          newItems = appContext.cartItems || [];
        }
        if (newItems.length > 1) {
          newItems.push(userCartData.Item);
        } else {
          newItems.push(userCartData.Item);
        }

        newAppContext = {
          userInfo: appContext.userInfo,
          cart: updatedCart,
          cartItems: newItems,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      } catch (error) {
        if (error.response.status === 401) {
          newAppContext = {
            userInfo: null,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        }
        toast.error(error);
      }
    }
  }

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? props.userOrders.length - 1 : prevIndex - 1
    );
    var needToCollapse = document.querySelector('.card-body:not(.collapse)');
    if (needToCollapse) {
      needToCollapse.classList.add("collapse");
    }
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === props.userOrders.length - 1 ? 0 : prevIndex + 1
    );
    var needToCollapse = document.querySelector('.card-body:not(.collapse)');
    if (needToCollapse) {
      needToCollapse.classList.add("collapse");
    }
  };

  return (
    <>
      {props.userOrders && props.userOrders.length >= 1 ? (
        <div className="recentOrders mx-2 mt-1 mb-2">
          <div className="fs-4 mb-1">Quickly Reorder:</div>
          <div className="slider mb-1">
            <div className="slides_container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {props.userOrders?.map((order, index) => (
                <div key={order.orderID} className={`order card slide ${index % 2 === 0 ? 'even' : 'odd'}`}>
                  <div
                    key={order.items[0].orderItemID}
                    className="card-header"
                  >
                    <div className="order_container d-flex flex-column">
                      <div>
                        <div className="item">{order.items[0].itemName}</div>
                      </div>
                      {order.items.length > 1 && (
                        <div className="otherItems">
                          + {order.items.length - 1} item(s)
                        </div>
                      )}
                      {order.items.length > 1 ? (
                        <div className="container">
                          <div className="date">{order.orderedDate}</div>
                          <div
                            className="viewDetails"
                            onClick={() => viewDetails(order)}
                          >
                            View Details
                          </div>
                        </div>
                      ) : (
                        <div className="container my-2 px-2">
                          <div className="date">{order.orderedDate}</div>
                          <div
                            className="viewDetails"
                            onClick={() => viewDetails(order)}
                          >
                            View Details
                          </div>
                        </div>
                      )}

                    </div>
                    <button
                      onClick={() => addItemsToCart(order)}
                    >
                      Reorder
                    </button>
                  </div>

                  <div
                    className="card-body collapse"
                    id={`${order.orderID}_body`}
                  >
                    {order.items?.map((item) => (
                      <div
                        className="item d-flex flex-column"
                        key={item.orderItemID}
                      >
                        <div className="itemName fw-bold fs-6">
                          {item.itemName}
                        </div>
                        <div className="extras d-flex">
                          {item.extras?.map((extra, index) => (
                            <div
                              key={extra.orderItemExtraID}
                              className="extraItem"
                            >
                              {extra.extraName}
                              {index !== item.extras.length - 1 ? ",  " : ""}
                            </div>
                          ))}
                        </div>
                        {item.itemSpecialRequests && (
                          <div className="requests">
                            {"'" + item.itemSpecialRequests + "'"}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="button prev" id="recent_orders_button_prev" onClick={handlePrevClick}>‹</div>
            <div className="button next" id="recent_orders_button_next" onClick={handleNextClick}>›</div>
          </div>
        </div>
      ) : null}
      <></>
    </>
  );
}

export default RecentOrders;
