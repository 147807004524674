import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./giftCard.scss";
import MxAddPaymentMethod from "../../components/checkout/MxAddPaymentMethod";
import AddPaymentMethod from "../../components/checkout/AddPaymentMethod";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import {
    APPLICATION,
    BASE_API,
    IS_DEMO_DEMO,
} from "../../constants";
import StoreContext from "../../StoreContext";
import ApplicationContext from "../../ApplicationContext";
import axios from "axios";
import { AiFillPlusCircle } from "react-icons/ai";

function GiftCard(props) {
    const appContext = useContext(ApplicationContext);
    const storeContext = useContext(StoreContext);

    const [noAccount, setNoAccount] = useState(true);
    const [selectedAmount, setSelectedAmount] = useState();
    const [radioSelectedAmount, setRadioSelectedAmount] = useState();
    const [giftCodeCheck, setGiftCodeCheck] = useState("");
    const [giftCodeValueCheck, setGiftCodeValueCheck] = useState();
    const [newGiftCardCode, setNewGiftCardCode] = useState();

    const [radioAmount, setRadioAmount] = useState("0");
    const [userInput, setUserInput] = useState(false);
    const [radioInput, setRadioInput] = useState(false);

    const [cardNumber, setCardNumber] = useState();
    const [expMonth, setExpMonth] = useState();
    const [expYear, setExpYear] = useState();
    const [cvcCode, setCvcCode] = useState();
    const [expDate, setExpDate] = useState();
    const [zipCode, setZipCode] = useState();

    const [customerPaymentProfiles, setCustomerPaymentProfiles] = useState([]);
    const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(true);
    const [selectedPayment, setSelectedPayment] = useState();
    const [previouslyActivePayment, setPreviouslyActivePayment] = useState();

    const [isDemo, setIsDemo] = useState("");

    const captchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState("");
    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };
    const resetCaptcha = () => {
        if (captchaRef.current) {
            captchaRef.current.reset();
            setCaptchaToken("");
        }
    };


    useEffect(() => {
        const pathname = window.location.pathname;
        const store = pathname.split("/")[1];
        if (store === "demostore" || store === "demostore2") {
            if (storeContext.mxMerchant === "true") {
                setIsDemo("MxMerchantDemo");
            } else {
                setIsDemo("AuthorizeNetDemo");
            }
        } else {
            if (storeContext.mxMerchant === "true") {
                setIsDemo("MxMerchant");
            } else {
                setIsDemo("AuthorizeNet");
            }
        }

        if (IS_DEMO_DEMO) {
            const pathname = window.location.pathname;
            const store = pathname.split("/")[1];

            if (!appContext.userInfo) {
                setNoAccount(true);
            } else {
                setNoAccount(false);
                if (storeContext.mxMerchant !== "true" && appContext.userInfo.customerID) {
                    if (store === "demostore" || store === "demostore2") {
                        getCustomerProfile(appContext.userInfo.customerID, "AuthorizeNetDemo");
                    } else {
                        getCustomerProfile(appContext.userInfo.customerID, "AuthorizeNet");
                    }
                } else if (storeContext.mxMerchant === "true" && appContext.userInfo.mxCustomerID) {
                    if (store === "demostore" || store === "demostore2") {
                        getCustomerProfile(appContext.userInfo.mxCustomerID, "MxMerchantDemo");
                    } else {
                        getCustomerProfile(appContext.userInfo.mxCustomerID, "MxMerchant");
                    }
                }
            }
        }
    }, [IS_DEMO_DEMO, appContext, storeContext]);

    useEffect(() => {
        if (storeContext.mxMerchant === "true") {
            if (customerPaymentProfiles.recordCount === 1 && isAddingPaymentMethod) {
                setSelectedPayment(customerPaymentProfiles.records[0]);
                setIsAddingPaymentMethod(false);
            } else if (customerPaymentProfiles.recordCount === 1 && !isAddingPaymentMethod) {
                setSelectedPayment(customerPaymentProfiles.records[0]);
            } else if (customerPaymentProfiles.recordCount > 1) {
                setIsAddingPaymentMethod(false);
            } else {
                setSelectedPayment();
                setIsAddingPaymentMethod(true);
            }
        } else {
            if (customerPaymentProfiles.length === 1 && isAddingPaymentMethod) {
                setSelectedPayment(customerPaymentProfiles[0]);
                setIsAddingPaymentMethod(false);
            } else if (customerPaymentProfiles.length === 1 && !isAddingPaymentMethod) {
                setSelectedPayment(customerPaymentProfiles[0]);
            } else if (customerPaymentProfiles.length > 1) {
                setIsAddingPaymentMethod(false);
            } else {
                setSelectedPayment();
                setIsAddingPaymentMethod(true);
            }
        }
    }, [customerPaymentProfiles]);

    async function invalidSubmit() {
        if (!selectedAmount || !radioSelectedAmount) {
            toast.error("Please select a payment method.");
        }

        if (document.getElementById("submit")) {
            document.getElementById("submit").disabled = true;

            setTimeout(function () {
                document.getElementById("submit").disabled = false;
            }, 3000);
        }
    }

    const handleMxSubmit = async (e) => {
        if (!appContext.userInfo) {
            props.setShowAuthenticateModal(true);
            return;
        }
        e.preventDefault();

        if (!captchaToken) {
            toast.error("Please complete the CAPTCHA to prove you are not a robot!");
            return;
        }

        if (document.getElementById("submit")) {
            document.getElementById("submit").disabled = true;
            document.getElementById("button-text").innerText = "PROCESSING...";

            setTimeout(function () {
                document.getElementById("submit").disabled = false;
                document.getElementById("button-text").innerText = "COMPLETE CHECKOUT";
            }, 3000);
        }
        if (!selectedPayment && (cardNumber && expMonth && expYear && cvcCode)) {
            //if card details are entered: charge the card
            var mxPaymentResult;
            var mxCustomerResult;
            var authCode;

            try {
                //send payment details to mx merchant
                mxPaymentResult = await authorizeMxPayment();
                mxPaymentResult = JSON.parse(mxPaymentResult.response.content);

                var errorData = {
                    OrderID: "GIFT CARD REDEMPTION: ",
                    Message:
                        appContext.userInfo.phoneNumber +
                        " - new card - " +
                        appContext.userInfo.role +
                        " " +
                        mxPaymentResult.authMessage,
                };
                await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, errorData, {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            } catch (error) {
                console.error("Error in payment authorization:", error);
            } //IF transaction is successful send order details to database (this is handled by handleCheckout)

            var paymentId = mxPaymentResult.id;
            if (mxPaymentResult.status === "Approved") {
                // CREATE TEXT MESSAGE AND EMAIL SERVICE
                await sendOrderConfirmationText(paymentId);

                if (appContext.userInfo) {
                    if (!appContext.userInfo.mxCustomerID) {
                        //if they are NOT a CUSTOMER in mx merchant yet (so we do createNewMxCustomer)
                        //create new customer in mx merchant
                        mxCustomerResult = await createNewMxCustomer();
                        mxCustomerResult = JSON.parse(mxCustomerResult.data.content);

                        var mxCustomerId = mxCustomerResult.id;
                        mxCustomerId = mxCustomerId.toString();
                        await updateCustomerMxID(mxCustomerId);

                        await createNewMxVaultedPayment(mxCustomerId);
                    }
                } else {
                    //else they ARE a CUSTOMER already in mx merchant (so we do NOT createNewMxCustomer)
                    mxCustomerId = appContext.userInfo.mxCustomerID;

                    await createNewMxVaultedPayment(mxCustomerId);
                }
            } else {
                toast.error("Payment Declined. Card Number error. Please check your card details and try again.");
                return;
            }
        } else if (selectedPayment) {
            //else the card details are NOT entered charge the selected payment method
            try {
                mxCustomerId = appContext.userInfo.mxCustomerID;
                var chargeVaultedPaymentResult = await authorizeChargeMxValuedPayment(
                    selectedPayment.token
                );
                chargeVaultedPaymentResult = JSON.parse(
                    chargeVaultedPaymentResult.response.content
                );

                var data = {
                    OrderID: "GIFT CARD REDEMPTION: ",
                    Message:
                        appContext.userInfo.phoneNumber +
                        " - payment profile - " +
                        appContext.userInfo.role +
                        " " +
                        chargeVaultedPaymentResult.authMessage,
                };
                await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            } catch (error) {
                console.error("Error in vaulted payment authorization:", error);
            } //IF transaction is successful send order details to database (this is handled by handleCheckout)

            var paymentId = chargeVaultedPaymentResult.id;
            if (chargeVaultedPaymentResult.status === "Approved") {
                // CREATE TEXT MESSAGE AND EMAIL SERVICE
                await sendOrderConfirmationText(paymentId);
            } else {
                toast.error("Payment Declined. Card Number error. Please check your card details and try again.");
                return;
            }
        }
    }
    async function authorizeMxPayment() {
        try {
            var mxData = {
                tenderType: "Card",
                cardAccount: {
                    number: cardNumber,
                    expiryMonth: expMonth,
                    expiryYear: expYear,
                    cvv: cvcCode,
                },
                GiftCardValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                OriginalValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                Name: appContext.userInfo.firstName + " " + appContext.userInfo.lastName,
                PhoneNumber: appContext.userInfo.phoneNumber,
            };

            mxData.mike = captchaToken;
            mxData.amount = selectedAmount ? selectedAmount : radioSelectedAmount;

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/authorize-and-capture-mxmerchant-payment`,
                JSON.stringify(mxData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            setNewGiftCardCode(dataresp.data.giftCode);

            resetCaptcha();
            return dataresp.data;
        } catch (err) {
            toast.error(err);
        }
    }
    async function authorizeChargeMxValuedPayment(paymentToken) {
        try {
            var mxData = {
                tenderType: "Card",
                cardAccount: {
                    token: paymentToken,
                },
                GiftCardValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                OriginalValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                Name: appContext.userInfo.firstName + " " + appContext.userInfo.lastName,
                PhoneNumber: appContext.userInfo.phoneNumber,
            };

            mxData.mike = captchaToken;
            mxData.amount = selectedAmount ? selectedAmount : radioSelectedAmount;

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/authorize-and-capture-mxmerchant-vaultedpayment`,
                JSON.stringify(mxData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            setNewGiftCardCode(dataresp.data.giftCode);

            resetCaptcha();
            return dataresp.data;
        } catch (err) {
            toast.error(err);
        }
    }
    async function updateCustomerMxID(profileID) {
        try {
            var newUser;
            if (
                appContext.userInfo.role === "GuestUser" ||
                appContext.userInfo.role === "Guest"
            ) {
                newUser = appContext.userInfo;
                newUser.mxCustomerID = profileID;
            } else {
                newUser = appContext.userInfo;
                newUser.mxCustomerID = profileID;
                await axios.put(`${BASE_API}api/User/UpdateMxCustomerID`, profileID, {
                    headers: {
                        Authorization: `Bearer ${appContext.userInfo.token}`,
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            }
            var newAppContext = {
                userInfo: newUser,
                cart: appContext.cart,
                cartItems: appContext.cartItems,
            };
            props.setAppContext(newAppContext);
            localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } catch (err) {
            toast.error("Failed to create Customer ID");
        }
    }
    async function createNewMxCustomer() {
        try {
            var mxData = {
                tenderType: "Card",
                email: appContext.userInfo.email,
                phone: appContext.userInfo.phoneNumber,
            };
            if (
                appContext.userInfo.role === "GuestUser" ||
                appContext.userInfo.role === "Guest"
            ) {
                mxData.name = appContext.userInfo.firstName;
                mxData.firstName = appContext.userInfo.firstName;
            } else {
                mxData.name =
                    appContext.userInfo.firstName + " " + appContext.userInfo.lastName;
                mxData.firstName = appContext.userInfo.firstName;
                mxData.lastName = appContext.userInfo.lastName;
            }

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/create-mxmerchant-customer`,
                JSON.stringify(mxData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );

            return dataresp;
        } catch (err) {
            toast.error(err);
        }
    }
    async function createNewMxVaultedPayment(customerId) {
        try {
            var mxData = {
                code: "Card",
                isDefault: true,
                number: cardNumber,
                expiryMonth: expMonth,
                expiryYear: expYear,
                cvv: cvcCode,
                alias: "CustomerCardAccount",
                id: customerId,
            };

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/create-mxmerchant-customer-vaultedpayment`,
                JSON.stringify(mxData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );

            return dataresp;
        } catch (err) {
            toast.error(err);
        }
    }
    async function sendOrderConfirmationText(id) {
        try {
            var mxData = {
                id: id,
                contact: appContext.userInfo.phoneNumber,
            };
            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/send-payment-receipt`,
                JSON.stringify(mxData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );

            return dataresp;
        } catch (err) {
            toast.error(err);
        }
    }

    const handleAuthNetSubmit = async (e) => {
        if (!appContext.userInfo) {
            props.setShowAuthenticateModal(true);
            return;
        }
        e.preventDefault();

        if (!captchaToken) {
            toast.error("Please complete the CAPTCHA to prove you are not a robot!");
            return;
        }

        if (document.getElementById("submit")) {
            document.getElementById("submit").disabled = true;
            document.getElementById("button-text").innerText = "PROCESSING...";

            setTimeout(function () {
                document.getElementById("submit").disabled = false;
                document.getElementById("button-text").innerText = "COMPLETE CHECKOUT";
            }, 3000);
        }

        if (cardNumber && expDate && cvcCode && zipCode) {
            //if card details are entered charge the card
            var authNetPaymentResult;
            var profileId;
            var paymentProfileId;
            var transactionId;

            try {
                authNetPaymentResult = await authorizeAuthorizeNetPayment();
                var errorData = {
                    OrderID: "GIFT CARD REDEMPTION: ",
                    Message:
                        appContext.userInfo.phoneNumber +
                        " - new card - " +
                        appContext.userInfo.role +
                        " " +
                        authNetPaymentResult.data.transactionResponse.responseCode,
                };
                await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, errorData, {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            } catch (error) {
                console.error("Error in payment authorization:", error);
            }

            if (authNetPaymentResult.data.transactionResponse.responseCode === "1") {
                // CREATE TEXT MESSAGE AND EMAIL SERVICE
            } else {
                toast.error("Error charing your payment method.");
                return;
            }

            if (appContext.userInfo) {
                //if they ARE a CUSTOMER already in auth net (so we do NOT createNewAuthNetCustomer)
                if (appContext.userInfo.customerID) {
                    var createPaymentProfileResult =
                        await createNewAuthNetCustomerPaymentProfile();
                    profileId = appContext.userInfo.customerID;
                    paymentProfileId = createPaymentProfileResult.PaymentProfileID;
                    transactionId = authNetPaymentResult.TransactionID;

                    var newAppContext = {
                        userInfo: appContext.userInfo,
                        cart: null,
                        cartItems: null,
                    };
                    props.setAppContext(newAppContext);
                    localStorage.setItem("appContext", JSON.stringify(newAppContext));
                }
            } else {
                //else they are NOT a CUSTOMER in auth net yet (so we do createNewAuthNetCustomer)
                var newAuthNetCustomerResult = await createNewAuthNetCustomer();
                profileId = newAuthNetCustomerResult.ProfileID;
                paymentProfileId = newAuthNetCustomerResult.PaymentProfileID;
                transactionId = authNetPaymentResult.TransactionID;
            }
        } else if (selectedPayment) {
            //else the card details are NOT entered charge the selected payment method
            try {
                var chargePaymentProfileResult = await chargeCustomerByAuthNetPaymentProfile(
                    appContext.userInfo.customerID,
                    selectedPayment.customerPaymentProfileId
                );
                var data = {
                    OrderID: "GIFT CARD REDEMPTION: ",
                    Message:
                        appContext.userInfo.phoneNumber +
                        " - payment profile - " +
                        appContext.userInfo.role +
                        " " +
                        chargePaymentProfileResult.Res.responseCode,
                };
                await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            } catch (error) {
                console.error("Error in vaulted payment authorization:", error);
            } //IF transaction is successful send order details to database (this is handled by handleCheckout)

            if (chargePaymentProfileResult.Res.responseCode === "1") {
                // CREATE TEXT MESSAGE AND EMAIL SERVICE
            } else {
                toast.error("Error charing your payment method.");
                return;
            }
        }
    };
    async function authorizeAuthorizeNetPayment() {
        try {
            var authNetData = {
                CardNumber: cardNumber,
                ExpirationDate: expDate,
                CardCode: cvcCode,
                // Address: address,
                ZipCode: zipCode,
                ShippingCity: null,
                ShippingState: null,
                ShippingAddress: null,
                ShippingZipCode: null,
                Amount: selectedAmount ? selectedAmount : radioSelectedAmount,
                Email: appContext.userInfo.email,
                GiftCardValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                OriginalValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                Name: appContext.userInfo.firstName + " " + appContext.userInfo.lastName,
            };
            authNetData.mike = captchaToken;

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/authorize-and-capture-authorizenet-payment`,
                JSON.stringify(authNetData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            setNewGiftCardCode(dataresp.data.giftCode);

            resetCaptcha();
            return {
                data: dataresp.data.response,
                TransactionID: dataresp.data.response.transactionResponse.transId,
            };
        } catch (err) {
            toast.error(err);
        }
    }
    async function chargeCustomerByAuthNetPaymentProfile(profileId, paymentProfileId) {
        try {
            var chargeProfile = {
                Amount: selectedAmount ? selectedAmount : radioSelectedAmount,
                ProfileID: profileId,
                PaymentProfileID: paymentProfileId,
                ProfileID: profileId,
                PaymentProfileID: paymentProfileId,
                GiftCardValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                OriginalValue: selectedAmount ? selectedAmount : radioSelectedAmount,
                Name: appContext.userInfo.firstName + " " + appContext.userInfo.lastName,
            };

            chargeProfile.mike = captchaToken;

            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/ChargeCustomerPaymentProfileCaptureCreateCode`,
                chargeProfile,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            setNewGiftCardCode(dataresp.data.giftCode);

            resetCaptcha();
            return {
                TransactionID: dataresp.data.response.transactionResponse.transId,
                ProfileID: dataresp.data.response.transactionResponse.profile.customerProfileId,
                PaymentProfileID: dataresp.data.response.transactionResponse.profile.customerPaymentProfileId,
                Res: dataresp.data.response.transactionResponse,
            };
        } catch (err) {
            toast.error("Failed to charge customer payment profile.");
        }
    }
    async function updateCustomerAuthNetID(profileID) {
        try {
            var newUser;
            if (appContext.userInfo.role === "GuestUser") {
                newUser = appContext.userInfo;
                newUser.customerID = profileID;
            } else {
                newUser = appContext.userInfo;
                newUser.customerID = profileID;
                await axios.put(`${BASE_API}api/User/UpdateCustomerID`, profileID, {
                    headers: {
                        Authorization: `Bearer ${appContext.userInfo.token}`,
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                });
            }
            var newAppContext = {
                userInfo: newUser,
                cart: null,
                cartItems: null,
            };
            props.setAppContext(newAppContext);
            localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } catch (err) {
            toast.error("Failed to create Customer ID");
        }
    }
    async function createNewAuthNetCustomerPaymentProfile() {
        var authorizeNetCustomer = {
            cardNumber: cardNumber,
            expirationDate: expDate,
            cardCode: cvcCode,
            firstName: appContext.userInfo.firstName,
            lastName: appContext.userInfo.lastName,
            // address: address,
            zipCode: zipCode,
            email: "",
        };
        authorizeNetCustomer.email = appContext.userInfo.email;
        authorizeNetCustomer.customerProfileId = appContext.userInfo.customerID;

        var newData = JSON.stringify(authorizeNetCustomer);
        try {
            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/CreateCustomerPaymentProfile`,
                newData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            return {
                PaymentProfileID: dataresp.data.customerPaymentProfileId,
                ProfileID: dataresp.data.customerProfileId,
            };
        } catch (err) {
            toast.error("Failed to create Customer ID");
        }
    }
    async function createNewAuthNetCustomer() {
        var authorizeNetCustomer = {
            cardNumber: cardNumber,
            expirationDate: expDate,
            cardCode: cvcCode,
            // address: address,
            email: appContext.userInfo.email,
            zipCode: zipCode,
            merchantCustomerID: "",
        };
        authorizeNetCustomer.merchantCustomerID = appContext.userInfo.userID;
        if (
            appContext.userInfo.role === "GuestUser" ||
            appContext.userInfo.role === "Guest"
        ) {
            authorizeNetCustomer.firstName = appContext.userInfo.firstName;
        } else {
            authorizeNetCustomer.firstName = appContext.userInfo.firstName;
            authorizeNetCustomer.lastName = appContext.userInfo.lastName;
        }

        var newData = JSON.stringify(authorizeNetCustomer);
        try {
            const dataresp = await axios.post(
                `${BASE_API}api/${isDemo}/CreateNewAuthorizeNetCustomer`,
                newData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Application: APPLICATION,
                    },
                }
            );
            updateCustomerAuthNetID(dataresp.data.customerProfileId);

            return {
                PaymentProfileID: dataresp.data.customerPaymentProfileIdList[0],
                ProfileID: dataresp.data.customerProfileId,
            };
        } catch (err) {
            toast.error("Failed to create Customer ID");
        }
    }

    useEffect(() => {
        setSelectedAmount(null);
    }, [radioInput]);

    const handleAmountChange = (e) => {
        let value = e.target.value.replace(/[^0-9.]/g, "");
        value = value.replace(/(\.\d{2})\d+/, "$1"); // Allow only two decimal places
        setSelectedAmount(value)
        console.log(value)
        !value ? setUserInput(false) : setUserInput(true)
    };

    const handleRadioAmountChange = (e) => {
        setRadioSelectedAmount(e.target.value)
    };

    const handleCodeCheck = (e) => {
        let value = e.target.value;
        setGiftCodeCheck(value);
    };

    async function getCustomerProfile(id, isDemo) {
        if (APPLICATION) {
            if (storeContext.mxMerchant === "true") {
                const dataresp = await axios.get(
                    `${BASE_API}api/${isDemo}/get-mxmerchant-customer-vaultedpayment/${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Application: APPLICATION,
                        },
                    }
                );
                var d = JSON.parse(dataresp.data.content);
                setCustomerPaymentProfiles(JSON.parse(dataresp.data.content));
            } else {
                const dataresp = await axios.get(
                    `${BASE_API}api/${isDemo}/GetCustomerPaymentMethods/${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Application: APPLICATION,
                        },
                    }
                );
                setCustomerPaymentProfiles(dataresp.data.profile.paymentProfiles);
            }
        }
    }

    async function addPaymentMethod() {
        if (isAddingPaymentMethod === true) {
            if (previouslyActivePayment) {
                setSelectedPayment(previouslyActivePayment);
                var profileSelected = document.querySelector(
                    `.payment_profile_${previouslyActivePayment.customerPaymentProfileId}`
                );
                if (profileSelected) {
                    profileSelected.classList.add("Active");
                }
            }
        } else {
            var activeProfile = document.querySelector(".payment_profile.Active");
            if (activeProfile) {
                activeProfile.classList.remove("Active");
            }
            if (customerPaymentProfiles.length === 1) {
                setPreviouslyActivePayment(customerPaymentProfiles[0]);
            } else if (customerPaymentProfiles.length > 1) {
                setPreviouslyActivePayment(selectedPayment);
            }
            setSelectedPayment();
        }
        setIsAddingPaymentMethod(!isAddingPaymentMethod);
    }

    async function mxAddPaymentMethod() {
        if (isAddingPaymentMethod === true) {
            if (previouslyActivePayment) {
                setSelectedPayment(previouslyActivePayment);
                var profileSelected = document.querySelector(
                    `.payment_profile_${previouslyActivePayment.cardId}`
                );
                if (profileSelected) {
                    profileSelected.classList.add("Active");
                }
            }
        } else {
            var activeProfile = document.querySelector(".payment_profile.Active");
            if (activeProfile) {
                activeProfile.classList.remove("Active");
            }
            if (customerPaymentProfiles.recordCount === 1) {
                setPreviouslyActivePayment(customerPaymentProfiles.records[0]);
            } else if (customerPaymentProfiles.recordCount > 1) {
                setPreviouslyActivePayment(selectedPayment);
            }
            setSelectedPayment();
        }
        setIsAddingPaymentMethod(!isAddingPaymentMethod);
    }

    async function mxSetPaymentProfileForOrder(i) {
        if (!isAddingPaymentMethod) {
            var activeProfile = document.querySelector(".payment_profile.Active");
            if (activeProfile) {
                activeProfile.classList.remove("Active");
                setSelectedPayment();
            } else {
                var profileSelected = document.getElementById(
                    `payment_profile_${i.cardId}`
                );
                profileSelected.classList.add("Active");
                setSelectedPayment(i);
            }
        } else {
            toast.error(
                "Please close the add a new payment method to select a saved payment method."
            );
        }
    }

    async function setPaymentProfileForOrder(i) {
        if (!isAddingPaymentMethod) {
            var activeProfile = document.querySelector(".payment_profile.Active");
            if (activeProfile) {
                activeProfile.classList.remove("Active");
                setSelectedPayment();
            } else {
                var profileSelected = document.getElementById(
                    `payment_profile_${i.customerPaymentProfileId}`
                );
                profileSelected.classList.add("Active");
                setSelectedPayment(i);
            }
        } else {
            toast.error(
                "Please close the add a new payment method to select a saved payment method."
            );
        }
    }

    const handleSubmitFindGiftCardBalance = async () => {
        if (!giftCodeCheck) {
            return;
        }

        try {
            const response = await axios.get(`${BASE_API}api/GiftCard/GetGiftCardValue/${giftCodeCheck}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Application": APPLICATION, // Ensure this is correctly defined
                },
            });
            console.log(response);

            if (response.status === 200) {
                setGiftCodeValueCheck(response.data); // Store the balance value
            } else {
                setGiftCodeValueCheck(null); // Clear previous value
            }
        } catch (e) {
            setGiftCodeValueCheck(null); // Clear previous value
        }
    };


    return (
        <>
            <Navbar
                setAppContext={props.setAppContext}
                setAuthKey={props.setAuthKey}
                setShowAuthenticateModal={props.setShowAuthenticateModal}
            />
            <div className="giftCard_content">

                {newGiftCardCode ? (
                    <div className="giftCard_header giftCard_headerFull">
                        <div className="newGiftCardContainer">
                            <div className="img_container">
                                <img src={`/pictures/${storeContext.nameCode}.png`} alt="" />
                            </div>
                            <div>
                                <div className="message">Congratulations on your purchase of a Gift Card with {storeContext.name}!</div>
                                <div className="message2">(This Gift Card is for Online Orders only)</div>
                                <div className="message2">(Gift Card Code can be redeemed at checkout)</div>
                            </div>
                            <div className="giftCard_codeContainer">
                                <h2 className="mt-1">Your Gift Card Code</h2>
                                <div className="giftCard_code">{newGiftCardCode}</div>
                                <div className="giftCard_value">Gift Card Value: ${selectedAmount ? selectedAmount : radioSelectedAmount}</div>
                                <div className="message3">(Screenshot this page to save your Code)</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="giftCard_header">
                        <h2>Create a Gift Card</h2>
                        <div className="payment_inputs">
                            <div className="giftcard-radio">
                                <div className="radio-labels">
                                    <label>$10</label>
                                    <input 
                                        type="radio" 
                                        name="giftcard-amount" 
                                        value={10}
                                        checked={radioAmount === "10"}
                                        onChange={(e) => {
                                            setRadioAmount("10")
                                            setSelectedAmount(0)
                                            setRadioInput(true)
                                            setUserInput(false)
                                            handleRadioAmountChange(e)
                                        }}
                                    />
                                </div>
                                <div className="radio-labels">
                                    <label>$25</label>
                                    <input 
                                        type="radio"
                                        name="giftcard-amount"
                                        value={25}
                                        checked={radioAmount === "25"}
                                        onChange={(e) => {
                                            setRadioAmount("25")
                                            setSelectedAmount(0)
                                            setRadioInput(true)
                                            setUserInput(false)
                                            handleRadioAmountChange(e)
                                        }}
                                    />
                                </div>
                                <div className="radio-labels">
                                    <label>$50</label>
                                    <input 
                                        type="radio"
                                        name="giftcard-amount"
                                        value={50}
                                        checked={radioAmount === "50"}
                                        onChange={(e) => {
                                            setRadioAmount("50")
                                            setSelectedAmount(0)
                                            setRadioInput(true)
                                            setUserInput(false)
                                            handleRadioAmountChange(e)
                                        }}
                                    />
                                </div>
                            </div>
                            <label Style={"width: 90%;"}>Enter a Custom Amount</label>
                            <input
                                className="amount_input"
                                type="text"
                                value={selectedAmount}
                                onChange={(e) => {
                                    setUserInput(true)
                                    setRadioAmount("0")
                                    setRadioInput(false)
                                    handleAmountChange(e)
                                    setRadioSelectedAmount(0)
                                }}
                                placeholder="$0.00"
                            />
                        </div>

                        {(userInput || radioInput) && (
                            <div className="checkout_container">
                                {storeContext.mxMerchant === "true" ? (
                                    <div className="add_payment_method" onClick={mxAddPaymentMethod}>
                                        {isAddingPaymentMethod ? (
                                            <>
                                                <div className="add_paymentMethodTitle">
                                                    View Payment Methods
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="add_paymentMethodTitle">
                                                    Add new payment method
                                                </div>
                                            </>
                                        )}
                                        <AiFillPlusCircle />
                                    </div>
                                ) : (
                                    <div className="add_payment_method" onClick={addPaymentMethod}>
                                        <div className="add_paymentMethodTitle">
                                            {isAddingPaymentMethod ? (
                                                <>
                                                    <div className="add_paymentMethodTitle">
                                                        View Payment Methods
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="add_paymentMethodTitle">
                                                        Add new payment method
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <AiFillPlusCircle />
                                    </div>
                                )}
                                {!isAddingPaymentMethod ? (
                                    <>
                                        {storeContext.mxMerchant === "true" ? (
                                            <>
                                                {customerPaymentProfiles &&
                                                    customerPaymentProfiles.recordCount === 1 ? (
                                                    <div
                                                        className={`payment_profile payment_profile_${customerPaymentProfiles.records[0].cardId} Active`}
                                                    >
                                                        <div className="card_type">
                                                            {customerPaymentProfiles.records[0].cardType}
                                                        </div>
                                                        <div className="card_number">
                                                            {customerPaymentProfiles.records[0].last4}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {customerPaymentProfiles &&
                                                            customerPaymentProfiles.recordCount > 0 && (
                                                                <>
                                                                    {customerPaymentProfiles.records.map(
                                                                        (i, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="payment_profile"
                                                                                id={`payment_profile_${i.cardId}`}
                                                                                onClick={() => mxSetPaymentProfileForOrder(i)}
                                                                            >
                                                                                <div className="card_type">{i.cardType}</div>
                                                                                <div className="card_number">{i.last4}</div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {customerPaymentProfiles.length === 1 ? (
                                                    <div
                                                        className={`payment_profile payment_profile_${customerPaymentProfiles[0].customerPaymentProfileId} Active`}
                                                    >
                                                        <div className="card_type">
                                                            {customerPaymentProfiles[0].payment.item.cardType}
                                                        </div>
                                                        <div className="card_number">
                                                            {customerPaymentProfiles[0].payment.item.cardNumber}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {customerPaymentProfiles.map((i, index) => (
                                                            <div
                                                                key={index}
                                                                className="payment_profile"
                                                                id={`payment_profile_${i.customerPaymentProfileId}`}
                                                                onClick={() => setPaymentProfileForOrder(i)}
                                                            >
                                                                <div className="card_type">
                                                                    {i.payment.item.cardType}
                                                                </div>
                                                                <div className="card_number">
                                                                    {i.payment.item.cardNumber}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {storeContext.mxMerchant === "true" ? (
                                            <MxAddPaymentMethod
                                                setCardNumber={setCardNumber}
                                                setExpMonth={setExpMonth}
                                                setExpYear={setExpYear}
                                                setCvcCode={setCvcCode}
                                                processor={"MxMerchant"}
                                            />
                                        ) : (
                                            <AddPaymentMethod
                                                setCardNumber={setCardNumber}
                                                setExpDate={setExpDate}
                                                setCvcCode={setCvcCode}
                                                setZipCode={setZipCode}
                                                processor={"AuthorizeNet"}
                                            />
                                        )}
                                    </>
                                )}


                                <div className="d-flex justify-content-center mt-3">
                                    <ReCAPTCHA
                                        sitekey="6Lcd7YQqAAAAALmui5JnWvTjD8jYBbtsSqJ6ndi-"
                                        ref={captchaRef}
                                        onChange={handleCaptchaChange}
                                    />
                                </div>

                                {(!selectedAmount && !radioSelectedAmount) || (!isAddingPaymentMethod && !selectedPayment) ? (
                                    <button id="submit" className="invalid" onClick={invalidSubmit}>
                                        <span id="button-text">COMPLETE CHECKOUT</span>
                                    </button>
                                ) : (
                                    <>
                                        {storeContext.mxMerchant === "true" ? (
                                            <button id="submit" className="checkout_button" onClick={handleMxSubmit}>
                                                <span id="button-text">COMPLETE CHECKOUT</span>
                                            </button>
                                        ) : (
                                            <button id="submit" className="checkout_button" onClick={handleAuthNetSubmit}>
                                                <span id="button-text">COMPLETE CHECKOUT</span>
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {!newGiftCardCode && (
                    <div className="giftCard_header">
                        <h2>Check Gift Card Balance</h2>
                        <div className="payment_inputs">
                            <label className="label">Gift Card Code</label>
                            <input
                                className="amount_input"
                                type="text"
                                value={giftCodeCheck}
                                onChange={handleCodeCheck}
                                placeholder="Enter your Gift Card Code"
                            />
                        </div>
                        <div className="getgcbalance_button">
                            <button onClick={handleSubmitFindGiftCardBalance}>Submit</button>
                        </div>
                        {giftCodeValueCheck > -1 && (
                            <div className="giftCard_balance">
                                <p>Remaining Balance: ${giftCodeValueCheck}</p>
                            </div>
                        )}
                    </div>
                )}

            </div>
            <Footer />
        </>
    );
}

export default GiftCard;
