import React, { useState } from "react";
import { IoIosMail, IoIosUnlock } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../constants";
import { formatUsersCart } from "../../utils/cart/FormatUsersCart";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_API}api/User/Login`,
        { email, password },
        {
          headers: {
            Application: APPLICATION,
          },
        }
      );
      var newAppContext;
      if (props.appContext.cart && props.appContext.cartItems) {
        const response = await axios.get(`${BASE_API}api/Cart/GetMyCart`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
            Application: APPLICATION,
          },
        });

        if (!props.appContext.cartItems) {
          if (response.data.Cart && response.data.CartItems.length >= 1) {
            var results = formatUsersCart(response);
            newAppContext = {
              userInfo: data,
              cart: results.loaded_cart,
              cartItems: results.loaded_cartItems,
            };

            props.setAppContext(newAppContext);
            localStorage.setItem("appContext", JSON.stringify(newAppContext));
          }
        } else {
          var cartContext = props.appContext.cart;
          cartContext.CartID = data.userID;
          var itemsContext = props.appContext.cartItems;

          for (var i = 0; i < itemsContext.length; i++) {
            itemsContext[i].CartItem_CartID = data.userID;
          }
          newAppContext = {
            userInfo: data,
            cart: cartContext,
            cartItems: itemsContext,
          };

          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        }

        if (response.data.CartItems.length < 1) {
          var cartContext = props.appContext.cart;
          cartContext.CartID = data.userID;
          newAppContext = {
            userInfo: data,
            cart: cartContext,
            cartItems: props.appContext.cartItems,
          };

          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        }
      } else {
        newAppContext = {
          userInfo: data,
          cart: props.appContext.cart,
          cartItems: props.appContext.cartItems,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      }
      toast.success("Login Successful");
      props.setShowAuthenticateModal(false);
    } catch (err) {
      toast.error("Login error");
    }
  };

  async function submit() {
    if (props.setUserChoice !== undefined) {
      props.setUserChoice(true);
    }
  }

  async function resetPassword() {
    props.setShowAuthenticateModal(false);
    props.setShowResetPasswordModal(true);
  }

  return (
    <div className="login_page loginregisterModal">
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="input_fields">
            <label>
              <IoIosMail style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="input_fields">
            <label>
              <IoIosUnlock style={{ width: "2em", height: "2em" }} />
            </label>
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <div className="submit">
            <button className="user" type="submit" onClick={submit}>
              Login
            </button>
          </div>

          {/* <div className="forgot_password" onClick={resetPassword}>
            Forgot My Password
          </div> */}
        </div>
      </form>
    </div>
  );
}

export default Login;
