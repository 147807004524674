import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';

const styles = {
  container: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px 0',
  },
  button: {
    transition: 'transform 0.2s ease',
    cursor: 'pointer',
  },
  buttonHover: {
    transform: 'scale(1.1)'
  }
};

const ShareButtons = () => {
  // Remove '/completion' from the URL if it exists
  const url = window.location.href.replace(/\/completion$/, '');
  const title = document.title;
  const description = '';

  return (
    <div style={styles.container}>
      <FacebookShareButton 
        url={url} 
        quote={description}
        style={styles.button}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <WhatsappShareButton 
        url={url} 
        title={title}
        style={styles.button}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TwitterShareButton 
        url={url} 
        title={title}
        style={styles.button}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <EmailShareButton 
        url={url} 
        subject={title} 
        body={description}
        style={styles.button}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
};

export default ShareButtons;
