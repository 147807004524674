import React from 'react';
import '../components/itemModal/itemModal.scss';

function SpecialRequest() {
  return (
    <div className="itemModal_specialRequests mt-3">
      <h5 className='head'>Special Requests:</h5>
      <input id="itemRequests" className="itemRequests_input w-100 py-1" />
      <p className="request_notice fs-7">
        <b>NOTICE: </b>Not all special requests can be completed. If the store is able to complete your request there may be circumstances 
        that require an additional fee. If so, we will need to charge your payment method the extra amount after 
        the order is processed.
      </p>
    </div>
  )
}

export default SpecialRequest;
