import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import MenuContext from "../../MenuContext";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import { CATEGORY_NAME, SUB_CATEGORY_NAME } from "../../constants";

function MenuCategories(props) {
  const menuContext = useContext(MenuContext);
  const [menuCategories, setMenuCategories] = useState([]);

  useLayoutEffect(() => {
    if (CATEGORY_NAME) {
      setActiveCategoryButton();
    }
    if (SUB_CATEGORY_NAME) {
      setActiveSubCategoryButton();
    }
  });

  async function setActiveCategoryButton() {
    var alreadyActiveCategory = document.querySelector(
      ".menu_category_button.Active"
    );
    if (!alreadyActiveCategory) {
      var category = CATEGORY_NAME.replace(/\s/g, "") + "Button";
      var setActiveCategory = document.getElementById(category);
      if (setActiveCategory) {
        setActiveCategory.classList.add("Active");
      }
    }
  }

  async function setActiveSubCategoryButton() {
    var alreadyActiveSubCategory = document.querySelector(
      ".subcategory.Active"
    );
    if (!alreadyActiveSubCategory) {
      var subCategory = SUB_CATEGORY_NAME.replace(/\s/g, "") + "Button";
      var setActiveSubCategory = document.getElementById(subCategory);
      if (setActiveSubCategory) {
        setActiveSubCategory.classList.add("Active");
      }
    }
  }

  useEffect(() => {
    if (menuContext.categories) {
      var menuCategoriesArray = [];
      var cats = Object.keys(menuContext.categories);

      for (var c = 0; c < cats.length; c++) {
        for (var i = 0; i < menuContext.categories[cats[c]].length; i++) {
          menuCategoriesArray.push(menuContext.categories[cats[c]][i]);
        }
      }

      // Move the "Specials" category to the front of the array
      var specialsIndex = menuCategoriesArray.findIndex(
        (category) =>
          category.category === "Specials" ||
          category.category === "Weekly Specials" || 
          category.category === "Super Bowl Specials" || 
          category.category === "Family Specials"
      );
      if (specialsIndex !== -1) {
        var specialsCategory = menuCategoriesArray.splice(specialsIndex, 1)[0];
        menuCategoriesArray.unshift(specialsCategory);
      }

      if (menuCategoriesArray.length === 1) {
        if (menuCategoriesArray[0].subcategories.length > 1) {
          props.setSubCategoryName(menuCategoriesArray[0].subcategories[0]);
        }
      }

      setMenuCategories(menuCategoriesArray);
    }
  }, [props.menuName, menuContext.categories]);

  useEffect(() => {
    var alreadyActiveCategory = document.querySelector(
      ".menu_category_button.Active"
    );
    if (alreadyActiveCategory) {
      alreadyActiveCategory.classList.remove("Active");
    }

    if (props.categoryName) {
      var category = props.categoryName.replace(/\s/g, "") + "Button";
      var setActiveCategory = document.getElementById(category);
      if (setActiveCategory) {
        setActiveCategory.classList.add("Active");
      }
    }
  }, [props]);

  useEffect(() => {
    var alreadyActiveSubCategory = document.querySelector(
      ".subcategory.Active"
    );
    if (alreadyActiveSubCategory) {
      alreadyActiveSubCategory.classList.remove("Active");
    }

    if (props.subCategoryName) {
      var subCategory = props.subCategoryName.replace(/\s/g, "") + "Button";
      var setActiveSubCategory = document.getElementById(subCategory);
      if (setActiveSubCategory) {
        setActiveSubCategory.classList.add("Active");
      }
    }
  }, [props]);

  async function scrollRight() {
    const buttonRight = document.getElementById("scroll_button_right");
    buttonRight.onclick = function () {
      document.getElementById("menu_categories").scrollLeft += 175;
    };
  }

  async function scrollLeft() {
    const buttonLeft = document.getElementById("scroll_button_left");
    buttonLeft.onclick = function () {
      document.getElementById("menu_categories").scrollLeft -= 175;
    };
  }

  async function setCategory(i) {
    var cat = i.category.replace(/\s/g, "");
    var el = document.getElementById(`${cat}Button`);
    var menu_categories = document.getElementById(`menu_categories`);
    const child = el.getBoundingClientRect();
    const parent = menu_categories.getBoundingClientRect();

    var isOverflowingRightSide = child.right > menu_categories.clientWidth;
    var isOverflowingLeftSide = child.left < parent.left;

    if (isOverflowingRightSide) {
      menu_categories.scrollLeft +=
        child.right - menu_categories.clientWidth + 175;
    }
    if (isOverflowingLeftSide) {
      menu_categories.scrollLeft -= parent.left + child.left + 175;
    }

    if (props.setCategoryName && props.setSubCategoryName) {
      props.setMenuName(i.menuName);
      props.setCategoryName(i.category);
      if (i.subcategories.length > 0) {
        props.setSubCategoryName(i.subcategories[0].subCategoryName);
      } else {
        props.setSubCategoryName("");
      }
    }
  }

  async function setSubCategory(j) {
    if (props.setSubCategoryName) {
      props.setSubCategoryName(j.subCategoryName);
    }
  }

  return (
    <>
      {menuCategories.length > 1 ? (
        <>
          <div className="menu_categories_container mt-1 mb-2">
            <div
              className="scroll_button scroll_button_left"
              onClick={scrollLeft}
            >
              <RiArrowLeftSLine id="scroll_button_left" />
            </div>
            <div className="menu_categories" id="menu_categories">
              {menuCategories?.map((i, index) => (
                <>
                  <button
                    key={index}
                    className="menu_category_button"
                    id={`${i.category.replace(/\s/g, "")}Button`}
                    onClick={(e) => setCategory(i)}
                  >
                    {i.category}
                  </button>
                </>
              ))}
            </div>
            <div
              className="scroll_button scroll_button_right"
              onClick={scrollRight}
            >
              <RiArrowRightSLine id="scroll_button_right" />
            </div>
          </div>

          <div className="menu_sub_categories_container mb-2">
            <div className="menu_sub_categories mx-1" id="menu_sub_categories">
              {menuCategories?.map((i, index) => (
                <div className="sub_category" key={index}>
                  {i.category === props.categoryName && (
                    <>
                      {i.subcategories?.map((j, index2) => (
                        <button
                          key={index2}
                          className="menu_category_button subcategory"
                          id={`${j.subCategoryName.replace(/\s/g, "")}Button`}
                          onClick={(e) => setSubCategory(j)}
                        >
                          {j.subCategoryName}
                        </button>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="menu_sub_categories_container mb-2">
            <div className="menu_sub_categories mx-1" id="menu_sub_categories">
              {menuCategories?.map((i, index) => (
                <div className="sub_category" key={index}>
                  {i.category === props.categoryName && (
                    <>
                      {i.subcategories?.map((j, index2) => (
                        <button
                          key={index2}
                          className="menu_category_button subcategory"
                          id={`${j.subCategoryName.replace(/\s/g, "")}Button`}
                          onClick={(e) => setSubCategory(j)}
                        >
                          {j.subCategoryName}
                        </button>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MenuCategories;